@import "custom-bootstrap";

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url('../../fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('../../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url('../../fonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
       url('../../fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* Colors */
$navbar-primary:		#2d6cb4;
$sidebar-primary:		#2d6cb4;
$sidebar-border-bottom:	#FFFFFF;
$sidebar-border-top:	#E7E7E7;
$sidebar-active:		darken($sidebar-primary, 10%);
$body-font-color: 		#555555;
$sidebar-font-color: 	#555555;
$sidebar-icons:			#555555;
$sidebar-hover:			lighten($sidebar-primary, 15%);
$sidebar-sub:			darken($sidebar-primary, 5%);
$item-border:			#CDD2D2;
$badge-red:				#DD5A43;
$color-light:			#F4F4F4;
$content-color:			#F5F6F6;
$color-green:			$brand-success;
/* Primary colors */
$primary-color:			$brand-primary;
$green-color:			$brand-success;
$blue-color:			$brand-info;
$orange-color:			$brand-warning;
$red-color:				$brand-danger;
$dark-color:			#555555;
$purple-color:			#A696CE;
$pink-color:			#DB5E8C;
$grey-color:			#898989;
$light-grey-color:		#E6E6E6;
$yellow-color:			#FCD76A;
$dark-orange-color:		#F38630;


/*-----------------------------------------------------------------------------------*/
/*	Homepage
/*-----------------------------------------------------------------------------------*/
/* Override */
html, body {
	height: 100%;
}
body {
	background: none repeat scroll 0 0 $white;
	border: 0 none;
	font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 13px;
	font-weight: 400;
	margin: 0;
	padding: 0;
	color: $body-font-color;
	/* overflow-x: hidden; */
}
h1, h2, h3, h4, h5, h6 {
	font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
	font-weight: 300 !important;
}
label {
	font-weight: 600;
}
.alert {
    padding: 15px;
}
.float-right{
    float: right;
}
.float-left{
    float: right;
}
.alert-info {
    color: #3a87ad !important;
}
/* Dotted outline removal */
a:active, a:focus{
    outline-style: none; -moz-outline-style:none;}
* {
	outline: medium none !important;
}
a:focus, a.focus {
    box-shadow: 0 0 0 0 transparent !important;
}
.nav-bar-admin{
  position: absolute;
right: 0px;
top: -3px;
direction: rtl;
font-size: 15px;
}
.nav-bar-admin .nav-link {
    padding: .9em 1rem;
}
/* Bootstrap extra */
.hidden-inline-mobile {
	display: inline !important;
}
@media (max-width: 768px) {
	.hidden-inline-mobile {
		display: none !important;
	}
}

/* Font weight */
.font-300 {
	font-weight: 300;
}
.font-400 {
	font-weight: 400;
}
.font-600 {
	font-weight: 600;
}
.font-700 {
	font-weight: 700;
}
/* Font size */
.font-11 {
	font-size: 11px;
}
.font-13 {
	font-size: 13px;
}
.font-14 {
	font-size: 14px;
}
/* Font colors */
.text-dark {
	color: $dark-color;
}
.text-purple {
	color: $purple-color;
}
.text-pink {
	color: $pink-color;
}
.text-yellow {
	color: $yellow-color;
}
.text-green {
	color: $green-color;
}
.text-white {
	color: #fff;
}
/* Icons */
.fa-1x {
	font-size: 1.5em;
}

/* Buttons */
.btn {
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.btn-inverse {
	@include button-variant(#fff, $dark-color, darken($dark-color, 5%));
	.caret {
		border-top-color: #FFFFFF;
	}
}
.btn-purple {
	@include button-variant(#fff, $purple-color, darken($purple-color, 5%));
	.caret {
		border-top-color: #FFFFFF;
	}
}
.btn-pink {
	@include button-variant(#fff, $pink-color, darken($pink-color, 5%));
	.caret {
		border-top-color: #FFFFFF;
	}
}
.btn-grey {
	@include button-variant(#fff, $grey-color, darken($grey-color, 5%));
}
.btn-light-grey {
	@include button-variant($grey-color, $light-grey-color, darken($light-grey-color, 5%));
}
.btn-yellow {
	@include button-variant(darken($yellow-color, 50%), $yellow-color, darken($yellow-color, 5%));
}
.btn-icon {
	margin: 10px 0;
	padding: 10px;
	transition: all 0.3s ease 0s;
}
.btn-icon:hover {
	border: 1px solid rgba(0, 0, 0, 0.3);
	transition: all 0.3s ease 0s;
}
.btn-icon:active {
	transition: none 0s ease 0s;
}
.btn-icon i {
	margin: 0;
}
.btn .label {
	border-radius: 12px 12px 12px 12px;
	border-style: solid;
	border-width: 0;
	box-shadow: none;
	color: #FFFFFF !important;
	font-size: 11px !important;
	font-weight: 300;
	padding: 3px 7px;
	position: absolute;
	text-shadow: none;
	top: 1px;
	&.label-right {
		right: 7px;
	}
	&.label-left {
		left: 7px;
	}

}
.input-block-level {
	display: block;
	width: 100%;
}
/* Container */
.container {
	width: 100% !important;
}
/* Dividers */
.divide-10 {
	height: 10px;
	width: 100%;
}
.divide-12 {
	height: 12px;
	width: 100%;
}
.divide-20 {
	height: 20px;
	width: 100%;
}
.divide-40 {
	height: 40px;
	width: 100%;
}
.divide-100 {
	height: 100px;
	width: 100%;
}
.margin-left-0 {
	margin-left: 0px !important;
}
.margin-left-50 {
	margin-left: 50px !important;
}
.margin-top-50 {
	margin-top: 50px !important;
}
.margin-top-100 {
	margin-top: 100px !important;
}
.margin-bottom-0 {
	margin-bottom: 0px !important;
}
.padding-top-10 {
	padding-top: 10px;
}
.padding-bottom-20 {
	padding-bottom: 20px;
}
.separator {
	height: 1px;
	border-bottom: 1px solid #FFFFFF !important;
	border-top: 1px solid #E7E7E7 !important;
	color: #555555 !important;
	margin: 10px 0 30px;
}

.fileinput-preview img{
	max-width: 100%;
	max-height: 100%;
}
#header a:focus {
    background-color: transparent !important;
}
.sidebar-menu>ul>li>a:focus {
    background-color: transparent !important;
}
#header{
    padding: 0 !important;
}
#header .nav-link{
    padding: .95rem 1rem !important;
}
.nav-header-right{
    justify-content: right;
    display: flex;
    width: 100%;
}
#header-user .nav-link {
    padding: 10px 10px 11px !important;
}
#header .skins a:hover {
     background-color: #fff !important;
}
#header-exercices select.form-control {
    max-width: 260px;
}
#header-budgetTypes .dropdown-item:hover, #header-budgetTypes .dropdown-item:focus {
    background-color: transparent;
}
#header-budgetTypes .dropdown-item {
    padding: 0.25rem 0.5rem;
}
.card .bg-primary .card-title,.card .bg-warning .card-title,.card .bg-success .card-title,.card .bg-danger .card-title,.card .bg-info .card-title,.card .bg-secondary .card-title{
    color: #fff !important;
}
.clear{
    clear:both;
}
/* Badges */
.badge-green {
	background-color: $brand-success;
}
.badge-orange {
	background-color: $brand-warning;
}
.badge-red {
	background-color: $brand-danger;
}
.badge-blue {
	background-color: $brand-info;
}
/* Scroller */
.scroller {
	margin: 0 !important;
	padding: 0 12px 0 0 !important;
}

/* Content */
#main-content {
	margin-left: 250px;
}

/* Content */
#content {
	background-color: $content-color;
	margin-top: 0;
	min-height: 800px;
	border-left: 1px solid $item-border;
	padding-bottom: 30px;
	/* Page Header */
	.page-header {
		background: none repeat scroll 0 0 #FFFFFF;
		border-bottom-color: $item-border;
		color: #000000;
		margin: 0 -15px 30px;
		min-height: 85px;
		overflow: hidden;
		padding: 0 20px;
		position: relative;
		.description {
			color: #888888;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 0;
			margin-bottom: 5px;
		}
	}
	.content-title {
		color: #666666;
		display: block;
		font-family: 'Open Sans';
		font-size: 30px;
		font-weight: 300;
		letter-spacing: -1px;
		margin: 5px 0;
		padding: 0;
	}
	/* Breadcrumbs */
	.breadcrumb {
		margin-bottom: 0;
		padding-right: 0px;
		padding-left: 0px;
		font-size: 14px;
		color: #333;
		background-color: #FFFFFF;
		a {
			text-shadow:none;
		}
	}
	.breadcrumb > li > i.fa-angle-right {
		color: #333;
		/*display: inline;
		font-size: 16px;
		font-family: "Font Awesome 5 Free";
		height: auto;
		content: "\f105";
		font-weight: 300;
		text-shadow:none;*/
		padding-left: 5px;
	}
}

.date-range {
	margin-top: 20px;
	.btn-group .btn{
		margin-top: -8px;
		padding: 8px;
	}
	.reportrange  {
		margin-right: 0px;
		margin-top: -8px;
		padding: 10px;
		cursor: pointer;
		min-width: 275x;
		color: #fff;
		border-radius: 0 4px 4px 0;
		background-color: $color-green;
		span {
			font-size: 12px;
			font-weight: 300;
			color: #fff;
			font-weight: normal;
		}
		.fa-calendar {
			text-transform: normal;
			color: #fff;
			margin-top: 0px;
			font-size: 14px;
		}
		.fa-angle-down {
			color:#fff;
			font-size: 16px;
		}
	}
}
/* Patterns */
.pattern {
	background-image: url("../../images/pattern.png");
}
.dots {
	background-image: url("../../images/dots.png");
}
/*-----------------------------------------------------------------------------------*/
/*	Grids and Box
/*-----------------------------------------------------------------------------------*/
$box-icon-color:			#C4C4C4;
$box-title-border-bottom:	#C4C4C4;
$box-border-title-color:	$sidebar-active;
$box-border-body-border:	#C4C4C4;
$box-solid-grey:			lighten($sidebar-icons, 10%);
$box-header-color:			#555555;
.box {
	clear: both;
	margin-top: 0px;
	margin-bottom: 25px;
	padding: 0px;
	.box-title {
		margin-bottom: 15px;
		border-bottom: 1px solid $box-title-border-bottom;
		min-height: 30px;
		h4 {
			font-family: 'Open Sans';
			display: inline-block;
			font-size: 18px;
			font-weight: 400;
			margin: 0;
			padding: 0;
			margin-bottom: 7px;
			color: $box-header-color;
			i {
				margin-right: 10px;
				color: #666;
			}
		}
		.tools {
			display: inline-block;
			padding: 0;
			margin-bottom: 7px;
			float: right;
			clear: both;
			min-height: 30px;
			> a {
				display: inline-block;
				font-size: 14px;
				line-height: 24px;
				text-decoration: none;
				color: $box-icon-color;
				margin-right: 5px;
				:hover {
					color: darken($box-icon-color, 10%);
				}
			}
			.label {
				margin-right: 5px;
			}
		}
		.items {
			display: inline-block;
			padding: 0;
			margin-bottom: 7px;
			margin-top: 7px;
			float: right;
			width: 40%;
			clear: both;
			.progress {
				height: 7px !important;
			}
		}
	}
	.toolbox {
		padding: 5px;
		background-color: $sidebar-primary;
		border-bottom: 1px solid $box-title-border-bottom;
	}
	.toolbox.bottom {
		padding: 5px;
		background-color: $sidebar-primary;
		border-top: 1px solid $box-title-border-bottom;
		border-radius: 0 0 4px 4px;
		border-bottom: none
	}
	.box-body.bg {
		border-radius: 0 0 4px 4px;
		padding: 10px;
		background-color: #FFFFFF;
		border-left: 1px solid $box-border-body-border;
		border-right: 1px solid $box-border-body-border;
		border-bottom: 1px solid $box-border-body-border;
		margin-top: -15px;
	}
}
/* Sortable */
.box-placeholder {
	border: 1px dashed $box-border-body-border;
	background-color: lighten($box-border-body-border, 15%);
	border-radius: 4px;
}
/* Colors */
.box.border.orange {
	$box-color: $brand-warning;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.pink {
	$box-color: $pink-color;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.purple {
	$box-color: $purple-color;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.inverse {
	$box-color: $dark-color;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.blue {
	$box-color: $brand-info;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.red {
	$box-color: $brand-danger;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.green {
	$box-color: $brand-success;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
.box.border.primary {
	$box-color: $brand-primary;
	border: 1px solid darken($box-color, 5%);
	> .box-title{
		color: #FFFFFF !important;
		background-color: $box-color;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
}
/* Box border */
.box.border, .box.solid{
	border: 1px solid $box-border-body-border;
	border-radius: 4px;
	> .box-title {
		padding: 8px 10px 2px;
		border-bottom: 1px solid $box-title-border-bottom;
		min-height: 30px;
		background-color: $box-border-title-color;
		/* border-radius: 4px 4px 0 0; */
		margin-bottom: 0;
		h4 {
			display: inline-block;
			font-size: 18px;
			font-weight: 400;
			margin: 0;
			padding: 0;
			margin-bottom: 7px;
			i {
				margin-right: 10px;
				color: #666;
			}
		}
		.tools {
			display: inline-block;
			padding: 0;
			margin-bottom: 7px;
			float: right;
			clear: both;
			min-height: 30px;
			> a{
				display: inline-block;
				font-size: 14px;
				line-height: 24px;
				text-decoration: none;
				color: darken($box-icon-color, 10%);
				margin-right: 5px;
				:hover {
					color: darken($box-icon-color, 20%);
				}
			}
		}
	}
	.box-body {
		border-radius: 0 0 4px 4px;
		padding: 10px;
		background-color: #FFFFFF;
	}
	.box-body.big {
		padding: 30px;
	}
	.box-title.big {
		padding: 15px 10px 7px;
	}
	.box-title.small {
		padding: 5px 10px 3px;
		h4 {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 3px;
		}
		.tools > a {
			font-size: 12px;
			line-height: 12px;
		}
	}
}
.box.border.lite {
	.box-title {
		background-color: lighten($box-border-title-color, 10%);
		h4 {
			color: lighten($box-header-color, 10%);
		}
	}
}
.box.solid.blue {
	$box-color: $navbar-primary;
	border: 1px solid darken($box-color, 5%);
	.box-title{
		color: #FFFFFF !important;
		background-color: $box-color !important;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
	.box-body {
		color: #FFFFFF;
		background-color: $box-color !important;
	}
}
.box.solid.grey {
	$box-color: $box-solid-grey;
	border: 1px solid darken($box-color, 5%);
	.box-title{
		color: #FFFFFF !important;
		background-color: $box-color !important;
		border-bottom: 1px solid darken($box-color, 5%);
		h4 {
			color: #FFFFFF !important;
			i {
				color: #FFFFFF !important;
			}
		}
		.tools {
			> a{
				color: $color-light !important;
				:hover {
					color: $color-light !important;
					opacity: 0.6 !important;
				}
			}
		}
	}
	.box-body {
		color: #FFFFFF;
		background-color: $box-color !important;
	}
}
.box:after, .box:before {
	display: table;
	color: "";
}
/* Misc */
.list-group-header {
	background-color: #F9F9F9;
	font-size: 11px;
	font-weight: 400;
	text-transform: uppercase;
	color: lighten($box-header-color, 10%);
}
/*-----------------------------------------------------------------------------------*/
/*	Elements
/*-----------------------------------------------------------------------------------*/
.example-modal .modal {
	bottom: auto;
	display: inline;
	margin: 0 auto;
	left: auto;
	position: relative;
	right: auto;
	top: auto;
	z-index: 1;
	width: 100%;
	.modal-dialog {
		width: 100%;
	}
}
.center {
	text-align: center;
}
#themes {
	.title {
		margin-right: 8px;
	}
	.btn.active .tick:before {
		float: left;
		margin-top: 0px;
		margin-right: 5px;
		display: inline;
		font-size: 13px;
		font-family: "Font Awesome 5 Free";
		height: auto;
		content: "\f00c";
		font-weight: 300;
		text-shadow:none;
	}
}
#messenger {
	.btn {
		i {
			font-size: 18px;
		}
	}
}
div.hint {
	background-color: #F8F8F8;
	border-radius: 5px;
	color: #333;
	display: inline-block;
	height: 27px;
	text-align: center;
	width: 135px;
	vertical-align: middle;
	margin-top: 5px;
	font-weight: 600;
}
/* Labels and Arrows */
.label.arrow-out, .label.arrow-in {
	border-radius: 0 !important;
	position: relative;
	z-index: 1;
}
.label.arrow-out:before, .label.arrow-in:before {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: rgba(0, 0, 0, 0) #ABBAC3 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
	border-image: none;
	border-style: solid;
	border-width: 1px;
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	z-index: -1;
}
.label.arrow-in:before {
	border-bottom-color: #ABBAC3;
	border-left-color: rgba(0, 0, 0, 0) !important;
	border-right-color: #ABBAC3;
	border-top-color: #ABBAC3;
}
.label.arrow-out-right, .label.arrow-in-right {
	position: relative;
	z-index: 1;
}
.label.arrow-out-right:after, .label.arrow-in-right:after {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ABBAC3;
	border-image: none;
	border-style: solid;
	border-width: 1px;
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	z-index: -1;
}
.label.arrow-in-right:after {
	border-bottom-color: #ABBAC3;
	border-left-color: #ABBAC3;
	border-right-color: rgba(0, 0, 0, 0) !important;
	border-top-color: #ABBAC3;
}

.label-info.arrow-out:before {
	border-right-color: $brand-info;
}
.label-info.arrow-in:before {
	border-color: $brand-info;
}
.label-info.arrow-out-right:after {
	border-left-color: $brand-info;
}
.label-info.arrow-in-right:after {
	border-color: $brand-info;
}
.label-primary.arrow-out:before {
	border-right-color: $brand-primary;
}
.label-primary.arrow-in:before {
	border-color: $brand-primary;
}
.label-primary.arrow-out-right:after {
	border-left-color: $brand-primary;
}
.label-primary.arrow-in-right:after {
	border-color: $brand-primary;
}
.label-success.arrow-out:before {
	border-right-color: $brand-success;
}
.label-success.arrow-in:before {
	border-color: $brand-success;
}
.label-success.arrow-out-right:after {
	border-left-color: $brand-success;
}
.label-success.arrow-in-right:after {
	border-color: $brand-success;
}
.label-warning.arrow-out:before {
	border-right-color: $brand-warning;
}
.label-warning.arrow-in:before {
	border-color: $brand-warning;
}
.label-warning.arrow-out-right:after {
	border-left-color: $brand-warning;
}
.label-warning.arrow-in-right:after {
	border-color: $brand-warning;
}
.label-danger.arrow-out:before {
	border-right-color: $brand-danger;
}
.label-danger.arrow-in:before {
	border-color: $brand-danger;
}
.label-danger.arrow-out-right:after {
	border-left-color: $brand-danger;
}
.label-danger.arrow-in-right:after {
	border-color: $brand-danger;
}
.label-inverse.arrow-out:before {
	border-right-color: $dark-color;
}
.label-inverse.arrow-in:before {
	border-color: $dark-color;
}
.label-inverse.arrow-out-right:after {
	border-left-color: $dark-color;
}
.label-inverse.arrow-in-right:after {
	border-color: $dark-color;
}
.label {
	height: 20px;
	line-height: 1.15;
}
.label.arrow-out {
	margin-left: 5px;
}
.label.arrow-out:before {
	border-width: 9px 5px 10px;
	left: -10px;
}
.label.arrow-in {
	margin-left: 5px;
}
.label.arrow-in:before {
	border-width: 9px 5px 10px;
	left: -5px;
}
.label.arrow-out-right {
	margin-right: 5px;
}
.label.arrow-out-right:after {
	border-width: 9px 5px 10px;
	right: -10px;
}
.label.arrow-in-right {
	margin-right: 5px;
}
.label.arrow-in-right:after {
	border-width: 9px 5px 10px;
	right: -5px;
}
.label > span, .label > [class*="fa-"] {
	line-height: 1;
}
.ui-slider {
	margin-bottom: 8px;
	margin-top: 10px;
}
.slider-eq > span {
	float: left;
	height: 125px;
	margin: 15px;
}
/* Sliers & Progress bar */
.slider {
	background: none repeat scroll 0 0 #E4E6EB !important;
	border: 0 none !important;
	border-radius: 50em 50em 50em 50em !important;
	position: relative;
	z-index: 10;
}
.ui-slider-horizontal {
	height: 0.7em !important;
}
.ui-slider-vertical {
	width: 0.7em !important;
}
.progress {
	background: none repeat scroll 0 0 #E4E6EB !important;
	border: 0 none !important;
	border-radius: 50em 50em 50em 50em !important;
	box-shadow: none;
	height: 14px !important;
	margin-top: -2px;
	position: relative;
	&.progress-sm {
		height: 9px !important;
	}
	&.progress-lg {
		height: 17px !important;
	}
}
.progress .ui-progressbar {
	border-radius: 50em 50em 50em 50em !important;
}
.progress .ui-progressbar-value {
	border: 0 none;
	border-radius: 50em 50em 50em 50em;
	box-shadow: none;
	height: 100%;
	left: 1px;
	position: absolute;
	top: 1px;
}
/* Animated progress */
.pbar .ui-progressbar-value {display:block !important}
.pbar.progress {
	overflow: hidden;
	margin-bottom: 5px;
	margin-top: 3px;
}
.percent {position:relative;text-align: right;}
.elapsed {position:relative;text-align: right;}

/* Slider & Progressbar colors */
.slider-blue .ui-slider-range, .progress-blue .ui-progressbar-value {
	background: none repeat scroll 0 0 $blue-color;
}
.slider-green .ui-slider-range, .progress-green .ui-progressbar-value {
	background: none repeat scroll 0 0 $green-color;
}
.slider-pink .ui-slider-range, .progress-pink .ui-progressbar-value {
	background: none repeat scroll 0 0 $pink-color;
}
.slider-orange .ui-slider-range, .progress-orange .ui-progressbar-value {
	background: none repeat scroll 0 0 $orange-color;
}
.slider-red .ui-slider-range, .progress-red .ui-progressbar-value {
	background: none repeat scroll 0 0 $red-color;
}
.slider-yellow .ui-slider-range, .progress-yellow .ui-progressbar-value {
	background: none repeat scroll 0 0 $yellow-color;
}
.slider-dark .ui-slider-range, .progress-dark .ui-progressbar-value {
	background: none repeat scroll 0 0 $dark-color;
}
/* Progress bar colors */
.progress-bar-yellow {
	background-color: $yellow-color;
}
.progress-bar-pink {
	background-color: $pink-color;
}
.progress-bar-dark {
	background-color: $dark-color;
}
/* Tabs and Accordions */
.box .header-tabs .nav-tabs {
	border-bottom: 0 none;
	margin-right: 0;
	overflow: hidden;
	position: relative;
	top: -43px;
	> li {
		float: right;
		margin-left: 1px;
		> a {
			position: relative;
			background: #eee;
			/* background-image: linear-gradient(to bottom, #fff, #ddd);  */
			padding: 7px 15px;
			float: left;
			text-decoration: none;
			color: #444;
			text-shadow: 0 1px 0 rgba(255,255,255,.8);
			border-radius: 5px 0 0 0;
			box-shadow: 0 2px 2px rgba(0,0,0,.4);
			margin-right: 15px;
			border-top: 1px solid darken(#ddd, 5%);
			i {
				margin-right: 5px;
			}
			&:after {
				content:'';
				position:absolute;
				z-index: 1;
				top: -1px;
				right: -5em;
				bottom: 0;
				width: 1em;
				background: #eee;
				/* background-image: linear-gradient(to bottom, #fff, #ddd);  */
				box-shadow: 2px 2px 2px rgba(0,0,0,.4);
				border-top: 1px solid darken(#ddd, 5%);
				transform: skew(10deg);
				-webkit-transform: skew(10deg);
				border-radius: 0 5px 0 0;
			}
			&:before {
				content:'';
				position:absolute;
				z-index: 1;
				top: -1px;
				left: -10px;
				bottom: 0;
				width: 1em;
				background: #eee;
				/* background-image: linear-gradient(to bottom, #fff, #ddd);  */
				border-left: 1px solid darken(#ddd, 5%);
				border-top: 1px solid darken(#ddd, 5%);
				transform: skew(170deg);
				-webkit-transform: skew(170deg);
				border-radius: 5px 0 0 0;
			}
		}
		&.active a, &.active a:after, &.active a:before{
			background: #fff;
			z-index: 3;
		}

	}
}
/* Header Tabs content */
.box .header-tabs .tab-content {
	margin-top: -30px;
}
/* Left, Right, Below tabs - removed from Bootstrap 3 */
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
	border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
	display: none;
}

.tab-content > .active,
.pill-content > .active {
	display: block;
}

.tabs-below > .nav-tabs {
	border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
	margin-top: -1px;
	margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
	border-top-color: #ddd;
	border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
	border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
	float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
	min-width: 74px;
	margin-right: 0;
	margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
	float: left;
	margin-right: 19px;
	border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
	margin-right: -1px;
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
	border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
	border-color: #ddd transparent #ddd #ddd;
	*border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
	float: right;
	margin-left: 19px;
	border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
	margin-left: -1px;
	-webkit-border-radius: 0 4px 4px 0;
	-moz-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
	border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
	border-color: #ddd #ddd #ddd transparent;
	*border-left-color: #ffffff;
}
/* Accordions */
.card-group .card-heading .accordion-toggle {
	display: block;
	text-decoration: none;
	font-size: 14px;
}
/* Tree */
.tree {
	overflow-x: hidden;
	overflow-y: auto;
	padding-left: 9px;
	position: relative;
}
.tree:before {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: #67B2DD;
	border-image: none;
	border-style: dotted;
	border-width: 0 0 0 1px;
	bottom: 16px;
	content: "";
	display: inline-block;
	left: 0;
	position: absolute;
	top: -20px;
	z-index: 1;
}
.tree .tree-folder {
	cursor: pointer;
	min-height: 20px;
	width: auto;
}
.tree .tree-folder .tree-folder-header {
	height: 100%;
	line-height: 20px;
	position: relative;
}
.tree .tree-folder .tree-folder-header:hover {
	background-color: lighten($light-grey-color, 5%);
}
.tree .tree-folder .tree-folder-header .tree-folder-name, .tree .tree-item .tree-item-name {
	display: inline;
	z-index: 2;
}
.tree .tree-folder .tree-folder-header > [class*="fa-"]:first-child, .tree .tree-item > [class*="fa-"]:first-child {
	display: inline-block;
	position: relative;
	top: -1px;
	z-index: 2;
}
.tree .tree-folder .tree-folder-header .tree-folder-name {
	margin-left: 2px;
}
.tree .tree-folder .tree-folder-header > [class*="fa-"]:first-child {
	margin: -2px 0 0 -2px;
}
.tree .tree-folder:last-child:after {
	border-left: 1px solid #FFFFFF;
	bottom: 0;
	content: "";
	display: inline-block;
	left: -15px;
	position: absolute;
	top: 15px;
	z-index: 1;
}
.tree .tree-folder .tree-folder-content {
	margin-left: 23px;
	position: relative;
}
.tree .tree-folder .tree-folder-content:before {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: #67B2DD;
	border-image: none;
	border-style: dotted;
	border-width: 0 0 0 1px;
	bottom: 16px;
	content: "";
	display: inline-block;
	left: -14px;
	position: absolute;
	top: -14px;
	z-index: 1;
}
.tree .tree-item {
	cursor: pointer;
	height: 100%;
	line-height: 20px;
	position: relative;
}
.tree .tree-item:hover {
	background-color: lighten($light-grey-color, 5%);
}
.tree .tree-item .tree-item-name {
	margin-left: 3px;
}
.tree .tree-item .tree-item-name > [class*="fa-"]:first-child {
	margin-right: 3px;
}
.tree .tree-item > [class*="fa-"]:first-child {
	margin-top: -1px;
}
.tree .tree-folder, .tree .tree-item {
	position: relative;
}
.tree .tree-folder:before, .tree .tree-item:before {
	border-top: 1px dotted #67B2DD;
	content: "";
	display: inline-block;
	height: 0;
	left: -13px;
	position: absolute;
	top: 14px;
	width: 18px;
	z-index: 1;
}
.tree .tree-selected {
	background-color: rgba(98, 168, 209, 0.1);
	color: #6398B0;
}
.tree .tree-selected:hover {
	background-color: rgba(98, 168, 209, 0.1);
}
.tree .tree-item, .tree .tree-folder {
	border: 1px solid #FFFFFF;
}
.tree .tree-folder .tree-folder-header {
	border-radius: 0 0 0 0;
}
.tree .tree-item, .tree .tree-folder .tree-folder-header {
	-moz-box-sizing: content-box;
	margin: 0;
	padding: 5px;
}
.tree .tree-item > [class*="fa-"]:first-child {
	-moz-box-sizing: content-box;
	background-color: #FAFAFA;
	border: 1px solid #CCCCCC;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	color: $light-grey-color;
	font-size: 11px;
	height: 13px;
	line-height: 13px;
	text-align: center;
	width: 13px;
}
.tree .tree-selected > [class*="fa-"]:first-child {
	background-color: $green-color;
	border-color: $green-color;
	color: #FFFFFF;
}
.tree .icon-plus[class*="fa-"]:first-child, .tree .icon-minus[class*="fa-"]:first-child {
	-moz-box-sizing: content-box;
	background-color: #FFFFFF;
	border: 1px solid #8BAEBF;
	height: 11px;
	line-height: 10px;
	position: relative;
	text-align: center;
	vertical-align: middle;
	width: 11px;
	z-index: 1;
}
.tree .icon-plus[class*="fa-"]:first-child:before {
	content: "+";
	display: block;
	font-family: "Open Sans";
	font-size: 16px;
	position: relative;
	z-index: 1;
}
.tree .icon-minus[class*="fa-"]:first-child:before {
	border-top: 1px solid #4D6878;
	content: "";
	display: block;
	height: 0;
	left: 2px;
	position: absolute;
	top: 5px;
	width: 7px;
}
.tree .tree-unselectable .tree-item > [class*="fa-"]:first-child {
	background-color: rgba(0, 0, 0, 0);
	border: 0 none;
	border-radius: 0 0 0 0;
	box-shadow: none;
	color: #5084A0;
	font-size: 10px;
	height: 13px;
	line-height: 13px;
	text-align: center;
	width: 13px;
}
.tree [class*="fa-"][class*="-down"] {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}
.tree .fa-adjust-hue {
	height: auto;
}
.tree .tree-loading {
	margin-left: 36px;
}
.tree img {
	display: inline;
}
/* Nestable lists */
.dd { position: relative; display: block; margin: 0; padding: 0; max-width: 600px; list-style: none; font-size: 13px; line-height: 20px; }

.dd-list { display: block; position: relative; margin: 0; padding: 0; list-style: none; }
.dd-list .dd-list { padding-left: 30px; }
.dd-collapsed .dd-list { display: none; }

.dd-item,
.dd-empty,
.dd-placeholder { display: block; position: relative; margin: 0; padding: 0; min-height: 20px; font-size: 13px; line-height: 20px; }

.dd-handle { display: block; cursor: move; height: 30px; margin: 5px 0; padding: 5px 10px; color: #333; text-decoration: none; font-weight: 600; border: 1px solid #ccc;
	background: #fafafa;
	background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
	background:    -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
	background:         linear-gradient(top, #fafafa 0%, #eee 100%);
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box; -moz-box-sizing: border-box;
}
.dd-handle:hover { color: #2ea8e5; background: #fff; }

.dd-item > button { display: block; position: relative; cursor: pointer; float: left; width: 25px; height: 20px; margin: 5px 0; padding: 0; text-indent: 100%; white-space: nowrap; overflow: hidden; border: 0; background: transparent; font-size: 12px; line-height: 1; text-align: center; font-weight: 600; }
.dd-item > button:before { content: '+'; display: block; position: absolute; width: 100%; text-align: center; text-indent: 0; }
.dd-item > button[data-action="collapse"]:before { content: '-'; }

.dd-placeholder,
.dd-empty { margin: 5px 0; padding: 0; min-height: 30px; background: #f2fbff; border: 1px dashed #b6bcbf; box-sizing: border-box; -moz-box-sizing: border-box; }
.dd-empty { border: 1px dashed #bbb; min-height: 100px; background-color: #e5e5e5;
	background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
	-webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-image:    -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
	-moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-image:         linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
	linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
	background-size: 60px 60px;
	background-position: 0 0, 30px 30px;
}

.dd-dragel { position: absolute; pointer-events: none; z-index: 9999; }
.dd-dragel > .dd-item .dd-handle { margin-top: 0; }
.dd-dragel .dd-handle {
	-webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
	box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
}

/**
 * Nestable Extras
 */

.nestable-lists { display: block; clear: both; padding: 30px 0; width: 100%; border: 0; border-top: 2px solid #ddd; border-bottom: 2px solid #ddd; }

#nestable-menu { padding: 0; margin: 20px 0; }

#nestable-output,
#nestable2-output { width: 100%; height: 7em; font-size: 0.75em; line-height: 1.333333em; font-family: Consolas, monospace; padding: 5px; box-sizing: border-box; -moz-box-sizing: border-box; }

#nestable2 .dd-handle {
	color: #fff;
	border: 1px solid #999;
	background: #bbb;
	background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
	background:    -moz-linear-gradient(top, #bbb 0%, #999 100%);
	background:         linear-gradient(top, #bbb 0%, #999 100%);
}
#nestable2 .dd-handle:hover { background: #bbb; }
#nestable2 .dd-item > button:before { color: #fff; }

@media only screen and (min-width: 700px) {

	.dd { float: left; width: 48%; }
	.dd + .dd { margin-left: 2%; }

}

.dd-hover > .dd-handle { background: #2ea8e5 !important; }

/**
 * Nestable Draggable Handles
 */

.dd3-content { display: block; height: 30px; margin: 5px 0; padding: 5px 10px 5px 40px; color: #333; text-decoration: none; font-weight: 600; border: 1px solid #ccc;
	background: #fafafa;
	background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
	background:    -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
	background:         linear-gradient(top, #fafafa 0%, #eee 100%);
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-sizing: border-box; -moz-box-sizing: border-box;
}
.dd3-content:hover { color: #2ea8e5; background: #fff; }

.dd-dragel > .dd3-item > .dd3-content { margin: 0; }

.dd3-item > button { margin-left: 30px; }

.dd3-handle { position: absolute; margin: 0; cursor: move; left: 0; top: 0; width: 30px; text-indent: 100%; white-space: nowrap; overflow: hidden;
	border: 1px solid #aaa;
	background: #ddd;
	background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
	background:    -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
	background:         linear-gradient(top, #ddd 0%, #bbb 100%);
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.dd3-handle:before { content: '≡'; display: block; position: absolute; left: 0; top: 3px; width: 100%; text-align: center; text-indent: 0; color: #fff; font-size: 20px; font-weight: normal; }
.dd3-handle:hover { background: #ddd; }
/* Tables inside Modals */
.modal-table {
	.modal-body{
		padding: 0;
	}
	.modal-footer {
		border-top: none;
		margin-top: 0;
	}
}
/* Forms */
.form-title {
	margin-top: 0px;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 400;
}
.field-icon {
	display: block;
	font-size: 14px;
	line-height: 1;
	position: absolute;
	right: 25px;
	top: 9px;
}
.textarea-transition {
	-webkit-transition: height 0.2s;
	-moz-transition: height 0.2s;
	transition: height 0.2s;
}
.radio > div.radio, .radio-inline > div.radio, .checkbox > div.checker, .checkbox-inline > div.checker {
	margin-left: -20px;
	padding-top: 0;
}
/* BT WYSIWYG */
#editor {
	margin-top: 10px;
	max-height: 250px;
	height: 250px;
	background-color: white;
	border-collapse: separate;
	border: 1px solid rgb(204, 204, 204);
	padding: 10px;
	box-sizing: content-box;
	-webkit-box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
	box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
	border-top-right-radius: 3px; border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px; border-top-left-radius: 3px;
	overflow: scroll;
	outline: none;
}
#voiceBtn {
	width: 20px;
	color: transparent;
	background-color: transparent;
	transform: scale(2.0, 2.0);
	-webkit-transform: scale(2.0, 2.0);
	-moz-transform: scale(2.0, 2.0);
	border: transparent;
	cursor: pointer;
	box-shadow: none;
	-webkit-box-shadow: none;
}

div[data-role="editor-toolbar"] {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dropdown-menu a {
	cursor: pointer;
}
/* Charts */
.chart, .pie, .bars {
	height: 300px;
	overflow: hidden;
}

.demo-container {
	height: 300px;
	border: 1px solid #ddd;
}

.demo-placeholder {
	width: 100%;
	height: 100%;
	font-size: 14px;
	line-height: 1.2em;
}

.legend table {
	border-spacing: 5px;
}
/* Xcharts */
.xchart .errorLine path {
	stroke: #C6080D;
	stroke-width: 3px;
}
.ex-tooltip {
	background: none repeat scroll 0 0 #EEEEEE;
	border-collapse: separate;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 1px 3px #000000;
	display: none;
	padding: 5px;
	position: absolute;
}

/* Easy pie chart */
.piechart {
	position: relative;
	display: inline-block;
	width: 110px;
	height: 110px;
	text-align: center;
	canvas {
		position: absolute;
		top: 0;
		left: 0;
	}
	.percent {
		font-size: 1.2em;
		font-weight: 600;
		display: inline-block;
		line-height: 110px;
		z-index: 2;
	}
}
/* Sparkline */
.sparkline-row {
	margin-bottom: 30px;
	position: relative;
	.big {
		height: 30px;
		width: 83px;
	}
	.title {
		display: block;
		font-size: 12px;
	}
	.value {
		font-size: 17px;
		font-weight: 600;
	}
	.sparkline, .sparklinepie, .linechart{
		position: absolute;
		right: 0;
		top: 0;
	}
	.sparklinepie{
		position: absolute;
		right: 0;
		top: 0;
	}
}
.sparkline-stats {
	position: relative;
	ul {
		list-style: none outside none;
		margin: 0 0 0 -40px;
		li {
			font-size: 12px;
			line-height: 32px;
			margin-bottom: 5px;
			padding-top: 3px;
			div {
				float: left;
				&:first-child {
					margin-right: 5px;
				}
			}
			.number {
				color: #FA603D;
				font-size: 17px;
				font-weight: 700;
				padding: 0 0 0 2px;
			}
		}
	}
}
/* Google Maps */
.gmaps {
	height: 300px;
	width: 100%;
}
/* Full calendar */
#external-events {
	padding: 0 10px;
	border: 1px solid #ccc;
	background: #eee;
	text-align: left;
	border-radius: 4px;
	margin-bottom: 20px;
}

#external-events h4 {
	font-size: 16px;
	margin-top: 0;
	padding-top: 1em;
}

.external-event { /* try to mimick the look of a real event */
	margin: 10px 0;
	padding: 2px 4px;
	background: $primary-color;
	color: #fff;
	cursor: move;
	font-size: 0.83em;
	border-radius: 4px;
}

#external-events p {
	margin: 1.5em 0;
	font-size: 11px;
	color: #666;
}

#external-events p input {
	margin: 0;
	vertical-align: middle;
}
/* Vector Maps */
.vmaps {
	height: 300px;
	overflow: hidden;
	position: relative;
}
/* Isotope filtering */
.isotope-item {
	z-index: 2;
}

.isotope-hidden.isotope-item {
	pointer-events: none;
	z-index: 1;
}
.isotope,
.isotope .isotope-item {
	/* change duration value to whatever you like */
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;
}

.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}

.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property:    -moz-transform, opacity;
	-ms-transition-property:     -ms-transform, opacity;
	-o-transition-property:      -o-transform, opacity;
	transition-property:         transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}
.filter-content {
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	border-radius: 5px;
	.hover-content {
		background-color: hsla(0,0%,0%,0.7);
		color: #FFFFFF;
		height: 100%;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 0;
		display: none;
		-webkit-animation-duration: 1s;
		-webkit-animation-delay: 0s;
		-moz-animation-duration: 1s;
		-moz-animation-delay: 0s;
		-o-animation-duration: 1s;
		-o-animation-delay: 0s;
		h4 {
			margin-top: 20%;
		}
		a.hover-link {
			margin: 0 5px;
			vertical-align: middle;
			i {
				color: #FFFFFF;
			}
		}
	}
}

/* Login Screen */
.login-v2 {
	background-color: #f6f7fa !important;
	height: 100%;
	min-height: 100%;
	#logo {
		margin-top: 30px;
		text-align: center;
	}
	#page {
		background: none repeat scroll 0 0 #f6f7fa;
	}
}
.login-box {
	width: 100%;
	max-width: 540px;
	border-radius: 10px;
        box-shadow: 0 5px 10px 0 rgba(48, 89, 158, 0.1);
        background-color: #fff;
	color: #1e1e1e;
	margin: 150px auto;
	padding: 30px;
	position: relative;
	min-height: 500px;
        font-family: 'Lato' !important;
        label{
            font-family: 'Lato' !important;
            font-size: 14px !important;
            font-weight: bold !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: 1.6 !important;
            letter-spacing: normal !important;
            color: #1e1e1e !important;
        }
        h1{
            font-family: 'Lato' !important;
            font-size: 34px !important;
            font-weight: bold !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: 1.62 !important;
            letter-spacing: normal !important;
            text-align: center !important;
            color: #1e1e1e !important;
            text-align: left !important;
        }
        .login-helpers{
            position:absolute;
            top:0px;            
            right:0px;
            margin: 0 !important;
            font-family: 'Lato' !important;
            font-size: 14px !important;
            font-weight: normal !important;
            font-stretch: normal !important;
            font-style: normal !important;
            line-height: 1.62 !important;
            letter-spacing: normal !important;
        }
        .login-helpers a:hover{
            background-color: transparent !important;
            text-decoration: underline !important;
        }
        label.checkbox{
            color: #7e7e7e !important;
            
        }
        .login-logo{
            text-align: center !important;
        }
	input, textarea {
		background: none repeat scroll 0 0 #FFFFFF;
		font-size: 15px;
		margin: 0;
		padding: 8px 8px !important;
                min-height:50px;
		width: 100%;
		border-radius: 5px;
                border: solid 1px #dddddd;
                background-color: #fff;
		box-shadow: none;
		min-width: 0;
		//padding-left: 33px !important;
	}
	button {
		width: auto;
                border-radius: 5px;
                background-color: #30599e;
                padding: 13px 30px;
                border-color: #30599e;
                float: right;
	}
        label.checkbox input{
            float:left;
            min-height:20px;
            margin-right: 10px;
        }
}
.login-box input#remember_me{
    width: auto;
}
#login_bg a, #register_bg a, #forgot_bg a {

    color: #30599e !important;
    text-decoration: none !important;

}
.login-box-plain {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #D8D8D8;
	border-radius: 7px 7px 7px 7px;
	box-shadow: 0 1px 4px #D3D3D3;
	margin: 50px auto;
	padding: 55px 60px 60px;
	position: relative;
	min-height: 300px;
	input, textarea {
		background: none repeat scroll 0 0 #FFFFFF;
		font-size: 15px;
		margin: 0;
		padding: 10px 8px 8px;
		width: 100%;
		border-radius: 6px;
		box-shadow: none;
		min-width: 0;
		padding-left: 33px !important;
	}
	button {
		width: 100%;
	}
}
.login-box  form i , .login-box-plain form i {
	color: #CCCCCC;
	display: block !important;
	font-size: 16px;
	height: 16px;
	margin: 9px 2px 4px 10px;
	position: absolute !important;
	text-align: center;
	width: 16px;
	z-index: 1;
}
.login-box .social-login a , .login-box-plain .social-login a{
	border: 0 none;
	border-radius: 100% 100% 100% 100%;
	height: 42px;
	line-height: 42px;
	margin: 0 5px;
	padding: 0;
	width: 42px;
	text-decoration: none !important;
}


.login-helpers {
	margin-top: 20px;
	font-size: 12px;
}
.form-control {
	font-size: 0.95rem;
}
.btn-info, .btn-success {
    color: $white !important;
}
.btn-info:hover, .btn-success:hover {
    color: $gray-dark !important;
}
.input-group-text{
    font-size: 0.95rem !important;
}
.thumbnail {
    display: block;
    padding: 4px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}
.thumbnail h4.text-muted{
    font-size: 1rem !important;
}
.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 37px !important;
      font-size: 0.95rem !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 36px !important;
}
.form-control:focus {
	border-color: #CCCCCC;
	box-shadow: none !important;
}
.bigintro {
	font-size: 38px;
	line-height: 50px;
	text-align: center;
}
.logo {
	a {
		transition: all 0.4s ease-in-out 0s;
	}
	a:hover {
		opacity: 0.7;
	}
	img {
		max-height: 50px;
	}
}
.visible {
	display: block !important;
}
#login, #register, #forgot{
	display: none;
}
#login_bg, #register_bg, #forgot_bg{
	display: none;
	a {
		color: #FFFFFF;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
/* Chat */
.chat-form {
	background-color: $sidebar-sub;
	clear: both;
	overflow: hidden;
	padding: 10px;
	border-radius: 4px;
}
.chat-pop {
	background-color: $sidebar-primary;
	padding: 8px;
	border-radius: 4px;
	position: relative;
	width: 70%;
	.media-heading {
		font-weight: 400;
	}
	&.mod {
		background-color: $sidebar-active;
		text-align: right;
	}
	h4 abbr{
		font-size: 11px;
		text-transform: uppercase;
	}
	h4 i{
		font-size: 13px;
	}
}
.chat-list  img {
	border-radius: 99px;
}
/* Chat widget */
.chat-widget {
	bottom: 0;
	position: fixed;
	right: 20px;
	width: 400px;
	z-index: 999;
	.box {
		margin-bottom: 0px;
	}
}
.card{
    margin-bottom: 15px;
}
.card-title {
		font-size: 20px;
                color: inherit;
                margin-bottom: 0;
	}
/* Address Book */
#contact-card {
	.card-title {
		font-size: 16px;
                color: inherit;
	}
	.card-body {
		.headshot img {
			border-radius: 4px;
		}
	}
}
/* Pricing */
/*Pricing table and price blocks*/
.pricing_table {
	line-height: 150%;
	margin: 0 auto;
	width: 100%;
	padding-top: 10px;
	.footer {
		padding: 15px;
		background: $sidebar-active;
		border-radius: 0 0 4px 4px;
	}
}

.price_block {
	text-align: center;
	color: #fff;
	float: left;
	list-style-type: none;
	transition: all 0.25s;
	position: relative;
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 10px;
	border-bottom: 1px solid transparent;
	padding-left: 0px;
	/* padding-right: 0px; */
}
.price_block_static {
	text-align: center;
	color: #fff;
	float: left;
	list-style-type: none;
	transition: all 0.25s;
	position: relative;
	box-sizing: border-box;
	border-radius: 4px;
	margin-bottom: 10px;
	padding-left: 0px;
	padding-right: 0px;
}

/*Price heads*/
.pricing_table h3 {
	text-transform: uppercase;
	padding: 5px 0;
	background: $dark-color;
	margin: 0;
	border-radius: 4px 4px 0 0;
}

/*Price tags*/
.price {
	display: table;
	background: $grey-color;
	width: 100%;
	height: 70px;
}
.price_figure {
	font-size: 24px;
	text-transform: uppercase;
	vertical-align: middle;
	display: table-cell;
}
.price_number {
	font-weight: 600;
	display: block;
}
.price_tenure {
	font-size: 11px;
}

/*Features*/
.features {
	background: $light-grey-color;
	color: #555555;
	padding: 0;
}
.features li {
	padding: 8px 15px;
	border-bottom: 1px solid #ccc;
	font-size: 11px;
	list-style-type: none;
}

.price_block:hover {
	/* box-shadow: 0 0 0px 5px rgba(0, 0, 0, 0.5); */
	transform: scale(1.04) translateY(-5px);
	-webkit-transform: scale(1.04) translateY(-5px);
	z-index: 1;
	border-bottom: 0 none;
}
.price_block_static.active {
	-webkit-transform: scale(1.09) translateY(-5px);
	transform: scale(1.09) translateY(-5px);
	z-index: 1;
	border-bottom: 0 none;
	box-shadow: 0 0 0px 3px rgba(0, 0, 0, 0.3);
}
.price_block:hover .price.orange {
	background: $orange-color;
}
.price_block:hover .price.green {
	background: $green-color;
}
.price_block_static.active .price {
	background: $red-color;
}
.price_block:hover h3 {
	background: darken($dark-color, 10%);
}
@media only screen and (min-width : 480px) and (max-width : 768px) {
	/* .price_block:nth-child(odd) {border-right: 5px solid transparent;} */
	.price_block:nth-child(3) {clear: both;}

	.price_block:nth-child(odd):hover {border: 0 none;}
}
@media only screen and (min-width : 768px){
	/* .price_block {border-right: 5px solid transparent; border-bottom: 0 none;} */
	.price_block:last-child {border-right: 0 none;}

	.price_block:hover {border: 0 none;}
}
.skeleton, .skeleton ul, .skeleton li, .skeleton div, .skeleton h3, .skeleton span, .skeleton p {
	border: 5px solid rgba(255, 255, 255, 0.9);
	border-radius: 5px;
	margin: 7px !important;
	background: rgba(0, 0, 0, 0.05) !important;
	padding: 0 !important;
	text-align: left !important;
	display: block !important;

	width: auto !important;
	height: auto !important;

	font-size: 10px !important;
	font-style: italic !important;
	text-transform: none !important;
	font-weight: normal !important;
	color: black !important;
}
.skeleton .label {
	font-size: 11px !important;
	font-style: italic !important;
	text-transform: none !important;
	font-weight: normal !important;
	color: white !important;
	border: 0 none !important;
	padding: 5px !important;
	margin: 0 !important;
	float: none !important;
	text-align: left !important;
	text-shadow: 0 0 1px white;
	background: none !important;
}
.skeleton {
	display: none !important;
	margin: 100px !important;
	clear: both;
}
/* Invoice */
@media print {
	.invoice-header .btn-group {
		display: none;
	}
	.payment-info {
		display: table;
		float: none;
		padding: 5px;
		margin: 0px;
	}
	.seller {
		display: table;
		float: left;
		padding: 5px;
		margin: 0px;
	}
	.buyer {
		display: table;
		float: right;
		padding: 5px;
		margin: 0px;
	}
	.seller i,
	.buyer i,
	.payment-info i {
		display: none;
	}
	.seller address,
	.buyer address,
	.payment-info address {
		float: left;
		font-size: 14px;
		margin: 0px;
	}
	.invoice-btn-group {
		display: none !important;
	}
	.amount {
		margin: 0px !important;
		font-weight: 400;
		font-size: 20px;
		float: right;
	}
	.invoice-title {
		font-size: 15px !important;
	}
	.divide-100 {
		display: none;
	}
	hr {
		margin: 0px;
	}
}
.invoice-header {
	display: block;
	margin-bottom: 20px;
	h3 {
		font-weight: 400;
		margin-top: 0;
	}
}
.seller, .buyer , .payment-info{
	padding: 20px;
	i {
		color: #999999;
		float: left;
		font-size: 20px;
	}
	address{
		float: left;
		font-size: 15px;
		margin-left: 20px;
	}
}
.invoice-title {
	color: $primary-color;
	font-size: 21px;
	font-weight: 600;
	line-height: 1.4;
	margin-bottom: 20px;
	text-transform: uppercase;
}
.amount {
	margin-right: 30px;
	color: $red-color;
	font-weight: 600;
}
/* Orders */
.orders {
	padding: 0;
	opacity: 1;
	transition: opacity 0.2s linear 0s;
	.progress {
		margin-top: 10px;
	}
	&.no-opaque {
		opacity: 1;
	}
	&:hover {
		opacity: 1;
	}
	ul {
		margin: 0;
	}
	li {
		border-bottom: 1px solid #FFFFFF !important;
		border-top: 1px solid #E7E7E7 !important;
		background-color: $sidebar-primary;
		padding: 5px 20px;
		cursor: pointer;
		position: relative;
		&:hover {
			background-color: $sidebar-hover;
		}
		&:first-child {
			border-bottom: 1px solid #FFFFFF !important;
			border-top: none !important;
			border-radius: 4px 4px 0 0;
		}
		&:last-child {
			border-radius: 0 0 4px 4px;
		}
		.cost {
			font-weight: 600;
		}
		abbr, .fa-clock-o {
			color: #B3B3B3;
		}
	}
}
/* Search */
.search-results {
	margin: 20px 0;
	h4 {
		margin-bottom: 2px;
		a{
			color: $primary-color;
			text-decoration: underline;
		}
	}
	.url{
		margin-top: 0px;
		color: darken($green-color, 5%);
		margin-bottom: 5px;
	}
	img {
		margin-right: 10px;
		border-radius: 2px;
	}
}

/* 404 & 500 Errors*/

.not-found {
	.error {
		color: $green-color;
		font-size: 128px;
		font-weight: 300;
		letter-spacing: -10px;
		line-height: 128px;
		margin-bottom: 20px;
		margin-top: 0;
		position: relative;
		text-align: right;
		top: 35px;
		right: 50%;
	}
	.error-500 {
		color: $red-color;
		font-size: 128px;
		font-weight: 300;
		letter-spacing: -10px;
		line-height: 128px;
		margin-bottom: 20px;
		margin-top: 0;
		position: relative;
		text-align: right;
		top: 35px;
		right: 50%;
	}
	.content {
		display: inline-block;
		margin-left: 40px;
		padding-top: 0;
		text-align: left;
		h3 {
			text-transform: uppercase;
		}
	}
}

#not-found-bg {
	background: url(../../images/gallery/img/1.jpg) 0 0 no-repeat fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 100%;
	min-height: 100%;
	overflow-x: hidden;
	.container {
		max-width: 1000px !important;
	}
	.overlay {
		background: rgba(39,62,84,0.82);
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		min-height: 100%;
	}
	.error {
		color: #FFFFFF;
		font-size: 128px;
		font-weight: 600;
		letter-spacing: -10px;
		line-height: 128px;
		margin-bottom: 20px;
		margin-top: 0;
		position: relative;
		text-align: left;
		right: 0;
		margin-left: 0px;
	}
	.content {
		color: #FFFFFF;
		display: inline-block;
		margin-left: 0px;
		padding-top: 0;
		text-align: left;
		h3 {
			text-transform: uppercase;
		}
	}
}
/* FAQ */
#list-toggle {
	.list-group {
		i {
			margin-right: 10px;
			font-size: 15px;
		}
	}
}
/* USER PROFILE */
.user-profile.tabbable-custom > .tab-content {
	overflow: inherit;
}
.list-group-item.zero-padding {
	padding: 0;
}
.profile-details li i {
	color: $grey-color;
	cursor: pointer;
}
.profile-details li:hover i {
	color: $red-color;
}
.user-profile {
	.piechart {
		padding-bottom: 2px;
	}
	.skill-name {
		text-transform: uppercase;
	}
}
.feed-activity {
	border-bottom: 1px dotted #D0D8E0;
	border-left: 1px dotted #FFFFFF;
	border-right: 1px dotted #FFFFFF;
	padding: 10px 4px;
	position: relative;
	&:hover {
		background-color: #F4F9FD;
	}
	.roundicon {
		border-radius: 100% 100% 100% 100%;
		color: #FFFFFF;
		display: inline-block;
		font-size: 18px;
		height: 38px;
		line-height: 38px;
		margin-left: 0;
		margin-right: 15px !important;
		text-align: center;
		text-shadow: none !important;
		width: 38px;
		padding: 0px;
	}
	.time {
		bottom: 8px;
		position: absolute;
		right: 12px;
	}
}
.form-actions {
	background-color: #F5F5F5;
	border-top: 1px solid #E5E5E5;
	margin-bottom: 20px;
	margin-top: 10px;
	padding: 19px 20px 20px;
}

#pro_edit .subtitle {
	display: block;
	font-weight: 600;
	padding-bottom: 5px;
}

#pro_edit .title {
	margin-top: 18px;
}
 .form-group .col-form-label {
	padding-bottom: 5px;
        padding-top: 5px;
        margin-bottom: 0;
        text-align: right;
}

/* DASHBOARD */
.dashbox .card-body:before, .dashbox .card-body:after {
	clear: both;
	content: "";
	display: table;
	line-height: 0;
}
.dashbox {
	.card-body {
		padding: 0px;
	}
	.card-left {
		width: 30%;
		float: left;
		padding: 20px;
		border-radius: 4px 0 0 4px;
		color: #FFFFFF;
		text-align: center;
		background-color: $sidebar-primary;
		border-right: 1px solid #DDDDDD;
		&.red {
			i {
				color: #D9534F;
			}
		}
		&.blue {
			i {
				color: #70AFC4;
			}
		}
	}
	.card-right {
		width: 70%;
		float: right;
		padding-left: 20px;
		padding-top: 10px;
		border-radius: 0 4px 4px 0;
		position: relative;
		.number {
			font-size: 30px;
			font-weight: 400;
			color: #666666;
		}
		.title {
			text-transform: uppercase;
		}
		.label {
			position: absolute;
			right: 5px;
			top: 5px;
			font-size: 11px;
		}
	}
}

ul.stats {
	-moz-box-sizing: border-box;
	display: table;
	margin: 0;
	padding: 0;
	table-layout: fixed;
	width: 100%;
}
ul.stats:not(.no-dividers) li {
	border-right: 1px solid #D9D9D9;
}
ul.stats li {
	display: table-cell;
	padding: 15px;
}
ul.stats li:last-child {
	border-right: 0 none;
}
ul.stats li strong, ul.stats li small {
	display: block;
	padding: 5px 0;
	text-align: center;
}
ul.stats li strong {
	font-size: 25px;
	font-weight: bold;
}
ul.stats li small {
	color: #ADADAD;
	font-size: 11px;
}
/* Quick Pie charts Dashboards */
.quick-pie {
	.title {
		color: #555555;
		display: block;
		font-size: 11px;
		font-weight: 400;
		margin-top: 5px;
		text-transform: uppercase;
		&:hover {
			text-decoration: none;
		}
	}
}
/* FOOTER GOTO TOP */
.footer-tools {
	display: inline-block;
	float: right;
	.go-top {
		background-color: darken($sidebar-primary, 20%);
		cursor: pointer;
		display: block;
		font-size: 14px;
		margin-bottom: 0;
		margin-right: 0;
		margin-top: -2px;
		padding: 0 6px 2px;
		text-decoration: none;
		border-radius: 4px;
		color: #FFFFFF;
		text-transform: uppercase;
		i {
			color: #FFFFFF;
		}
	}
	&:hover {
		opacity: 0.7;
	}
}
/* GALLERY */
#filter-controls {
	margin-bottom: 20px;
}
.width-100 {
	width: 100%;
}
/* EMAIL LAYOUTS */
#email-layouts li {
	margin-bottom: 10px;
	font-size: 14px;
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
	white-space: pre-wrap; /* css-3 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}
/* FONT AWESOME */
#font-awesome {
	a {
		text-decoration: none;
		font-size: 14px;
		font-weight: 300;
		margin-bottom: 5px;
		color: #555555;
	}
}
/* GRITTER */
#gritter-notice-wrapper {
	right: 1px !important;
}
.gritter-close {
	left: auto !important;
	right: 3px !important;
}
.gritter-title {
	font-family: 'Open Sans';
	font-size: 18px;
	font-weight: 300;
}
/* WELL BOTTOM */
.well {
	&.well-bottom {
		border-radius: 0 0 4px 4px;
	}
}

.responsive-table-container {
	overflow-x: auto;
	padding: 0px;
}
.responsive-table-container div.dataTables_wrapper {
	width: 99.5%;
}
@media (max-width: 767.98px) {
	.login-box{
		margin: 10px auto;
	}

}