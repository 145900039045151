@import "../common/custom-variables";
/* Colors */
$navbar-primary:		#5E87B0;
$sidebar-primary:		#F4F4F4;
$sidebar-border-bottom:	#FFFFFF;
$sidebar-border-top:	#E7E7E7;
$sidebar-active:		darken($sidebar-primary, 10%);
$body-font-color: 		#555555;
$sidebar-font-color: 	#555555;
$sidebar-icons:			#555555;
$sidebar-hover:			lighten($sidebar-primary, 15%);
$sidebar-sub:			darken($sidebar-primary, 5%);
$item-border:			#CDD2D2;
$badge-red:				#DD5A43;
$color-light:			#F4F4F4;
$content-color:			#F5F6F6;
$color-green:			$brand-success;
/* Primary colors */
$brand-primary:         #5E87B0;
$brand-success:         #A8BC7B;
$brand-warning:         #F0AD4E;
$brand-danger:          #D9534F;
$brand-info:            #70AFC4;
$primary-color:			$brand-primary;
$green-color:			$brand-success;
$blue-color:			$brand-info;
$orange-color:			$brand-warning;
$red-color:				$brand-danger;
$dark-color:			#555555;
$purple-color:			#A696CE;
$pink-color:			#DB5E8C;
$grey-color:			#898989;
$light-grey-color:		#E6E6E6;
$yellow-color:			#FCD76A;
$dark-orange-color:		#F38630;
.titre-change-color{
	color: #fff;
}
.slimScrollBar{
	background:#fff !important;
}
#page {
	background: none repeat scroll 0 0 $sidebar-primary;
}
.login #page {
	background: transparent;
}
.oeil{
	padding: 6px 12px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	color: #555;
	text-align: center;
	background-color: #eee;
	border: 1px solid #ccc;
	border-left-color: rgb(204, 204, 204);
	border-left-style: solid;
	border-left-width: 1px;
	border-radius: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-left: 0;
	display: table-cell;
	width: 63px;
	white-space: nowrap;
	vertical-align: middle;
	box-sizing: border-box;
	cursor:pointer;
}
/* Start Navbar */
.navbar {
	background: none repeat scroll 0 0 $navbar-primary;
	border: none;
	border-radius: 0;
	margin: 0;
	.navbar-brand {
		float: left;
		font-size: 18px;
		line-height: 20px;
		padding: 15px;
		width: 250px;
		img {
			position: absolute;
			max-width: 60%;
			height: 30px;
			width: 120px;
			top: 8px;
			left: 60px;
		}
	}
	.dropdown-toggle {
		color: #FFFFFF;
		i {
			color: #FFFFFF;
			font-size: 17px;
		}
		.badge {
			background-color: $badge-red;
			border-radius: 99px !important;
			font-size: 12px !important;
			font-weight: 300;
			height: 16px;
			padding: 2px 6px;
			position: absolute;
			right: 24px;
			text-align: center;
			text-shadow: none !important;
			top: 8px;
		}
		.username {
			font-weight: 600;
		}
	}
	#header-notification ,#header-message, #header-tasks{
		.dropdown-toggle {
			i {
				color: darken($navbar-primary, 10%);
				text-shadow: 1px 1px lighten($navbar-primary, 25%), -1px -1px darken($navbar-primary, 20%);
			}
		}
	}
	#navbar-left {
		li.dropdown {
			background-color: lighten($navbar-primary, 5%);
			border-right: 1px solid darken($navbar-primary, 5%);
			font-weight: 400;
		}
		li.dropdown:first-child {
			border-left: 1px solid darken($navbar-primary, 5%);
		}
		i  {
			margin-right: 5px;
			color: #FFFFFF;
		}
	}
	.nav.navbar-nav.float-right {
		min-width: 300px;
	}
}
.navbar {
	.nav > li > a:hover, .nav > li > a:focus, .nav .open > a, .nav .open > a:hover, .nav .open > a :focus{
		background-color: lighten($navbar-primary, 10%) !important;
	}
}
/* Navbar Dropdown */
.dropdown-menu {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #C7CBD5;
	display: none;
	float: left;
	left: 0;
	font-size: 14px;
	list-style: none outside none;
	margin: 0;
	max-width: 300px;
	min-width: 166px;
	padding: 0;
	position: absolute;
	text-shadow: none;
	top: 100%;
	z-index: 1000;
	font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
	.dropdown-title {
		background: none repeat scroll 0 0 darken($navbar-primary, 10%);
		color: #FFFFFF;
		display: block;
		font-weight: 600;
		line-height: 30px;
		padding: 5px 10px;
		i {
			margin-right: 5px;
		}
	}
	.footer {
		display: block;
		a{
			background-color: $sidebar-active !important;
			font-weight: 600;
			line-height: 30px;
			text-align: center;
			border-bottom: none !important;
			border-top: none !important;
			i {
				margin-left: 5px;
				font-size: 16px;
			}
		}
	}
	ul {
		margin: 0 !important;
		padding: 0 !important;
	}
}
/* Navbar Settings Menu */
.dropdown-menu.skins{
	border-top: 1px solid darken($navbar-primary, 10%);
}
/* Navbar Right Menu */
.dropdown-menu{
	background-color: $color-light;
	li {
		a {
			background-color: $color-light;
			border-bottom: 1px solid #FFFFFF;
			border-top: 1px solid #E7E7E7;
			display: block;
			line-height: 40px;
			padding: 0 10px;
		}
		a:hover {
			color: #333333;
			background-color: lighten($color-light, 20%);
		}
	}
	&.context { /* For smaller menus */
		li {
			a {
				line-height: 30px;
			}
		}
	}
	.divider {
		height: 1px;
		border-bottom: 0 !important;
		border-top: 1px solid darken(#E7E7E7, 10%) !important;
		color: #555555 !important;
		margin: 0 !important;
	}
}
/* Notification */
.dropdown-menu.notification{
	border-top: 1px solid darken($navbar-primary, 10%);
	width: 270px;
	.label {
		color: #FFFFFF;
		display: inline-block;
		margin: -1px 10px -5px -10px !important;
		padding: 9px;
		text-align: center;
		width: 40px;
		height: 42px;
		border-radius: 0;
		position: absolute;
		i{
			font-size: 18px;
		}
	}
	.body {
		position: relative;
		display: inline-block;
		line-height: 20px;
		vertical-align: middle;
		white-space: normal;
		margin: 5px 5px 5px 40px;
		width: 100%;
	}
	.message {
		line-height: 16px;
		display: inline-block;
		margin: 0 5px 5px 0;
	}
	.time {
		color: #777777;
		display: inline-block;
		font-size: 12px;
	}
}
/* Messages */
.dropdown-menu.inbox{
	border-top: 1px solid darken($navbar-primary, 10%);
	width: 280px;
	img {
		border-radius: 50em 50em 50em 50em;
		height: 40px;
		margin-right: 10px;
		margin-top: 5px;
		width: 40px;
	}
	.body {
		position: relative;
		display: inline-block;
		line-height: 20px;
		max-width: 220px;
		vertical-align: middle;
		white-space: normal;
		margin: 5px 0;
	}
	.from {
		display: inline-block;
		line-height: 14px;
		font-weight: 600;
		margin-bottom: 5px;
	}
	.message {
		line-height: 16px;
		display: block;
		margin-bottom: 5px;
	}
	.time {
		color: #777777;
		display: block;
		font-size: 12px;
	}
	.compose {
		display: block;
		:hover {
			cursor: pointer;
		}
	}
}
/* Tasks */
.dropdown-menu.tasks{
	border-top: 1px solid darken($navbar-primary, 10%);
	width: 250px;
	.progress {
		margin-top: 0 !important;
		margin-bottom: 10px !important;
		height: 9px !important;
	}
}
/* User Menu */
.dropdown.user {
	a.dropdown-toggle {
		padding: 10px 10px 11px;
	}
	.dropdown-menu {
		border-top: none;
	}
	img {
		margin-right: 5px;
		border-radius: 99px;
		height: 29px;
		width: 29px;
		/* filter: url("data:image/svg+xml;filter: gray;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); */
	}
	a:hover img, a:focus img{
		filter: none;
	}
}
/* End Navbar */
/* Start Sidebar */
#sidebar {
	background: none repeat scroll 0 0 $sidebar-primary;
	padding: 0 !important;
	width: 250px;
	position: absolute;
	border-bottom: 1px solid $sidebar-border-top;
}
.sidebar-fixed {
	position: fixed !important;
}
#sidebar * {
	text-overflow: ellipsis;
}
.sidebar-menu > ul {
	list-style: none;
	margin: 0;
	padding: 0;
	margin: 0;
	padding: 0;
	margin-top:15px;
	border-bottom: 1px solid #FFFFFF !important;
	border-top: 1px solid #FFFFFF !important;
}

.sidebar-menu > ul > li {
	display: block;
	margin: 0;
	padding: 0;
	border: 0px;
}

.sidebar-menu > ul > li > a {
	display: block;
	position: relative;
	margin: 0;
	border: 0 none;
	padding: 15px 15px 15px 20px;
	text-decoration: none;
	font-size: 13px;
	font-weight: 600;
	text-transform: uppercase;
}

.sidebar-menu > ul > li a i {
	color: $sidebar-icons;
	font-size: 16px;
	margin-right: 5px;
}

.sidebar-menu > ul > li.has-sub {
	position: relative;
}

.sidebar-menu > ul > li.has-sub  .sub{
	position: relative;
	background: $sidebar-sub;
	border-top: 1px solid $sidebar-border-top;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
	.has-sub-sub .sub-sub {
		position: relative;
		background: $sidebar-sub;
		border-top: 1px solid $sidebar-border-top;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
	}
}

.sidebar-menu > ul > li.active > a {
	border: none;
	text-shadow:none;
}

.sidebar-menu ul > li > a .arrow:before {
	float: right;
	margin-top: 1px;
	margin-right: 5px;
	display: inline;
	font-size: 16px;
	font-family: FontAwesome;
	height: auto;
	content: "\f0d9";
	font-weight: 300;
	text-shadow:none;
}

.sidebar-menu > ul > li > a .arrow.open:before {
	float: right;
	margin-top: 1px;
	margin-right: 5px;
	display: inline;
	font-family: FontAwesome;
	height: auto;
	font-size: 16px;
	content: "\f0d7";
	font-weight: 300;
	text-shadow:none;
}

.sidebar-menu > ul > li > ul.sub:before {
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
	border-color: darken($sidebar-icons, 10%);
	border-image: none;
	border-style: dotted;
	border-width: 0 0 0 1px;
	bottom: 0;
	content: "";
	display: block;
	position: absolute;
	top: 0;
	z-index: 1;
}

.sidebar-menu > ul > li > ul.sub > li:before {
	border-top: 1px dotted darken($sidebar-icons, 10%);
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	margin: 5% 0 0;
}

.sidebar-menu > ul > li > ul.sub {
	display: none;
	list-style: none;
	clear: both;
	padding-left: 25px;
	.has-sub-sub ul.sub-sub {
		display: none;
		list-style: none;
		clear: both;
	}
}

.sidebar-menu > ul > li.active > ul.sub {
	display: block;
	.has-sub-sub ul.sub-sub {
		display: block;
	}
}

.sidebar-menu > ul > li > ul.sub > li, .sidebar-menu > ul > li > ul.sub  .has-sub-sub ul.sub-sub > li {
	background: none;
	margin: 0px;
	padding: 0px;
	margin-top: 1px !important;
}

.sidebar-menu > ul > li > ul.sub > li > a {
	display: block;
	margin: 0px 0px 0px 0px;
	padding: 5px 0px;
	padding-left: 30px !important;
	color: $sidebar-icons;
	text-decoration: none;
	font-size: 13px;
	font-weight: 300;
	background: none;
}
.sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li > a {
	display: block;
	margin: 0px 0px 0px 0px;
	padding: 5px 0px;
	padding-left: 30px !important;
	color: $sidebar-icons;
	text-decoration: none;
	font-size: 13px;
	font-weight: 300;
	background: none;
}
.sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li :hover, .sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li :focus{
	text-decoration: underline;
}

.sidebar-menu > ul > li > ul.sub > li > a > i, .sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li > a > i {
	font-size: 13px;
}

.sidebar-menu > ul > li > a {
	border-bottom: 1px solid $sidebar-border-bottom !important;
	border-top: 1px solid $sidebar-border-top !important;
	color: $sidebar-font-color !important;
}

.sidebar-menu > ul > li:last-child > a {
	border-bottom: 1px solid transparent !important;
}

.sidebar-menu > ul > li:first-child > a {
	/* border-top-color: transparent !important; */
}

.sidebar-menu > ul > li a i {
	color: $sidebar-icons;
}

.sidebar-menu > ul > li.has-sub.open >a,
.sidebar-menu > ul > li >a:hover,
.sidebar-menu > ul > li:hover>a,{
	background: $sidebar-hover;
}

.sidebar-menu > ul > li.has-sub.open > ul > li.has-sub-sub.open > a {
	background: $sidebar-hover;
}

.sidebar-menu > ul > li.active > a{
	background: $sidebar-active !important;
	border-top-color: transparent !important;
	color:#fff;
}

.sidebar-menu > ul > li > a.open {
	background: #313131;
}

.sidebar-menu ul > li > a .arrow:before,
.sidebar-menu > ul > li > a .arrow.open:before {
	color: $sidebar-icons !important;
}

/*.sidebar-menu ul > li.active > a .arrow:before,
.sidebar-menu > ul > li.active > a .arrow.open:before {
   color: #fff !important;
} */

.sidebar-menu > ul > li > ul.sub > li > a {
	color: $sidebar-font-color;
	margin-left:0px;
	padding-left: 5px;
	font-size: 13px;
	font-weight: 400;
}

.sidebar-menu > ul > li > ul.sub > li:first-child > a {
	border-top: 0px !important;
}

.sidebar-menu > ul > li > ul.sub > li.active > a,
.sidebar-menu > ul > li > ul.sub > li > a:hover,
.sidebar-menu > ul > li > ul.sub > li.current {
	background: lighten($sidebar-sub, 5%) !important;
}

.sidebar-menu > ul > li > ul.sub > li > a:hover,{
	background: lighten($sidebar-sub, 5%) !important;
}
/* Search */
.sidebar-menu  #search-bar {
	display: block;
	text-align: center;
	.search {
		background: none repeat scroll 0 0 $sidebar-hover;
		border: 0 none;
		border-radius: 4px;
		color: $sidebar-font-color;
		padding: 8px 10px;
		width: 90%;
		margin: 0 auto 10px;
		padding-right: 30px !important;

	}
	.search-icon {
		display: block;
		font-size: 14px;
		line-height: 1;
		position: absolute;
		right: 22px;
		top: 29px;
		color: lighten($sidebar-icons, 20%);
	}
}
/* Mini Menu Search */
.mini-menu .sidebar-menu  #search-bar {
	display: block;
	text-align: center;
	.search {
		background: none repeat scroll 0 0 $sidebar-hover;
		border: 0 none;
		border-radius: 4px;
		color: $sidebar-font-color;
		padding: 8px 10px;
		width: 90%;
		margin: 0 auto 10px;
	}
	.search-icon {
		display: block;
		font-size: 14px;
		line-height: 1;
		position: absolute;
		right: 22px;
		top: 29px;
		color: lighten($sidebar-icons, 20%);
	}
}
/* Quick launch */
.sidebar-menu .quicklaunch-lg {
	line-height: 39px;
	margin-bottom: 0;
	max-height: 41px;
	text-align: center;
	.btn {
		margin-right: 1rem;
	}
}
.sidebar-menu .quicklaunch-mini {
	display: none;
	font-size: 0;
	line-height: 18px;
	padding-bottom: 2px;
	padding-top: 2px;
	text-align: center;
	width: 50px;
}
/* Sidebar collapse */
.sidebar-collapse, .switcher {
	padding: 10px 10px 0 0;
	position: absolute;
	top: 10px;
	left: 10px;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
	color: #FFFFFF;
	padding: 6px 8px;
}
.sidebar-collapse:hover, .switcher:hover {
	background-color: darken($navbar-primary, 5%);
}
.sidebar-collapse > [class*="fa-"] , .switcher > [class*="fa-"]{
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	padding: 0 5px;
	position: relative;
	float: right;
}
.menu-text {
	.badge {
		position: absolute;
		right: 35px;
		line-height: 15px;
		padding-bottom: 3px;
		padding-top: 2px;
		text-shadow: none;
		background-color: $badge-red !important;
	}
}
/* Min Menu */
.mini-menu#sidebar {
	width: 50px;
}
.mini-menu#sidebar:before {
	width: 50px;
}
.mini-menu#sidebar  .sidebar-menu > ul > li > a > .menu-text {
	.badge {
		top: 15px;
		right: 10px;
	}
	.label {
		position: absolute;
		top: 15px;
		right: 10px;
	}
}
.mini-menu#sidebar  .sidebar-menu > ul > li > a > .menu-text {
	background-color: $sidebar-primary;
	display: none;
	height: 50px;
	left: 50px;
	line-height: 38px;
	padding-left: 12px;
	position: absolute;
	top: 0;
	width: 198px;
	padding: 5px 0 0 15px;
	z-index: 121;
	border: 1px solid darken($sidebar-border-top, 10%);
}
.mini-menu#sidebar  .sidebar-menu > ul > li.has-sub > a > .menu-text {
	border-right: none;
	border-top: none;
}
.mini-menu#sidebar  .sidebar-menu > ul > li:hover > a > .menu-text {
	display: block;
}
.mini-menu#sidebar .sidebar-menu > ul > li > ul.sub {
	background-color: $sidebar-sub;
	border: 1px solid darken($sidebar-border-top, 10%);
	display: none !important;
	left: 50px;
	padding-bottom: 2px;
	padding-top: 50px;
	position: absolute;
	top: 0;
	width: 200px;
	z-index: 120;
	border-top: 1px solid $sidebar-border-top;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}
.mini-menu#sidebar .sidebar-menu > ul > li:hover > ul.sub {
	display: block !important;
}
.mini-menu#sidebar .sidebar-menu ul > li > a .arrow:before {
	display: none;
}
.mini-menu#sidebar .sidebar-menu > ul > li > a .arrow.open:before {
	display: none;
}
.mini-menu#sidebar .quicklaunch-mini {
	display: block;
}
.mini-menu#sidebar  #quicklaunch {
	position: relative;
}
.mini-menu#sidebar .quicklaunch-lg{
	background-color: $sidebar-primary;
	display: none;
	left: 50px;
	position: absolute;
	top: -1px;
	width: 190px;
	z-index: 20;
	padding: 0 0 0 1px;
	max-height: 50px;
	height: 115%;
	border: 1px solid darken($sidebar-border-top, 10%);
	.btn {
		margin-right: 0.25rem;
	}

}
.mini-menu#sidebar  #quicklaunch:hover .quicklaunch-lg {
	display: block;
}
/* End Sidebar */
/* Team Status */
.team-status {
	background-color: lighten($sidebar-icons, 10%);
	display: none;
	border-bottom: 2px solid $sidebar-icons;
}
.team-status.open {
	display: block;
}
.team-status .team-list {
	height: 100%;
	list-style: none outside none;
	margin: 0 auto;
	padding: 15px 10px 20px;
	text-align: center;
}
.team-status .team-list li {
	color: #FFFFFF;
	display: inline-block;
	padding: 0 30px;
}
.team-status .team-list li:last-child {
	border-right: 0 none;
}
.team-status .team-list li a {
	color: #FFFFFF;
	display: block;
	opacity: 0.9;
	padding: 15px;
	border-radius: 4px;
	min-width: 200px;
	.progress {
		margin-top: 10px !important;
		height: 9px !important;
		margin-bottom: 10px !important;
	}
	.status {
		text-align: left;
		font-size: 11px;
		text-transform: uppercase;
		.field {
			margin-bottom: 5px;
		}
	}
}
.team-status .team-list li a:hover {
	background-color: rgba(255, 255, 255, 0.07);
	opacity: 1;
	text-decoration: none;
}

.team-status .team-list li .image, .team-status .team-list li .title {
	display: block;
}
.team-status .team-list li .image img {
	height: 45px;
	width: 45px;
	border-radius: 150px 150px 150px 150px;
}
.team-status .team-list li .title {
	font-weight: 600;
}
.team-status .team-list li .image + .title {
	padding-top: 10px;
}
.team-status .team-list li.current a {
	background-color: rgba(255, 255, 255, 0.07);
	opacity: 1;
	text-decoration: none;
}
.selectDyn{
	width: 100%;
}