//@import "custom-bootstrap";
/* Colors
$navbar-primary:		#5E87B0;
$sidebar-primary:		#F4F4F4;
$sidebar-border-bottom:	#FFFFFF;
$sidebar-border-top:	#E7E7E7;
$sidebar-active:		darken($sidebar-primary, 10%);
$body-font-color: 		#555555;
$sidebar-font-color: 	#555555;
$sidebar-icons:			#555555;
$sidebar-hover:			lighten($sidebar-primary, 15%);
$sidebar-sub:			darken($sidebar-primary, 5%);
$item-border:			#CDD2D2;
$badge-red:				#DD5A43;
$color-light:			#F4F4F4;
$content-color:			#F5F6F6;
$color-green:			$brand-success;*/
/* Primary colors
$brand-primary:         #5E87B0;
$brand-success:         #A8BC7B;
$brand-warning:         #fbc815;
$brand-danger:          #D9534F;
$brand-info:            #70AFC4;
$primary-color:			$brand-primary;
$green-color:			$brand-success;
$blue-color:			$brand-info;
$orange-color:			$brand-warning;
$red-color:				$brand-danger;
$dark-color:			#555555;
$purple-color:			#A696CE;
$pink-color:			#DB5E8C;
$grey-color:			#898989;
$light-grey-color:		#E6E6E6;
$yellow-color:			#FCD76A;
$dark-orange-color:		#F38630;*/

$item-border:			#CDD2D2;

@media only screen and (max-width: 767px) {
	body {
		overflow-x: hidden;
	}
	/* NAVBAR */
	.navbar .navbar-nav.float-right {
		display: block;
		float: none !important;
		background-color: #404040;
		text-align: center;
	}
	.navbar {
		.nav > li > a:hover, .nav > li > a:focus, .nav .open > a, .nav .open > a:hover, .nav .open > a :focus{
			background-color: lighten(#404040, 10%) !important;
		}
	}
	.navbar {
		.navbar-brand {
			float: none;
			height: 45px;
			width: 100%;
			img {
				left: 10px;
			}
			a.switcher {
				position: absolute;
				left: auto;
				right: 70px;
				i {
					font-size: 14px;
				}
			}
		}
		.nav {
			li.dropdown {
				display: inline-block;
				vertical-align: middle;
			}
		}
		.dropdown-menu {
			position: absolute !important;
			box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
			text-align: left;
		}
		#header-notification ,#header-message, #header-tasks{
			.dropdown-toggle {
				i {
					color: #FFFFFF;
					text-shadow: none;
				}
			}
		}
	}
	.navbar-nav {
		margin: 5px -15px 0px;
		.open {
			.dropdown-menu {
				&.notification {
					min-width: 270px;
					right: auto !important;
					left: 0 !important;
				}
				&.inbox {
					min-width: 280px;
					left: -50px !important;
				}
				&.tasks {
					min-width: 250px;
					left: -100px !important;
				}
				> li > a {
					line-height: 40px;
					padding: 0 10px;
				}
				> li.footer > a {
					line-height: 30px;
					padding: 0 10px;
				}
			}
		}
		> li > a {
			padding: 15px;
		}
	}
	.dropdown.user {
		a.dropdown-toggle {
			padding: 10.5px;
		}
	}
	/* SIDEBAR */
	.sidebar {
		box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
		right: -250px;
		position: absolute;
		top: 0 !important;
		z-index: 1001;
		transition: right 0.3s ease 0s;
		display: none;
		-webkit-transform: scale3d(1,1,1);
		&.mini-menu {
			box-shadow: none;
			right: auto;
			top: auto !important;
			z-index: 1;
		}
	}
	body {
		right: 0;
		transition: right 0.3s ease 0s;
	}
	.slidebar {
		position: absolute;
		right: 250px;
		.sidebar {
			right: 0;
			width: 250px !important;
			display: block;
			height: 100%;
		}
		#content {
			border-right: 1px solid $item-border;
		}
	}
	.sidebar-collapse {
		right: 20px;
		left: auto;
	}
	/* DATE RANGE */
	.date-range {
		&.float-right {
			display: block;
			float: none !important;
			padding-bottom: 5px;
		}
	}
	/* CONTENT TITLE */
	.content-title {
		&.float-left {
			display: block;
			float: none !important;
		}
	}
	/* CALENDAR */
	.fc-header-left, .fc-header-center, .fc-header-right {
		display: block;
		float: none !important;
	}
	/* TABS AND ACCORDIONS */
	.box .box-tabs .nav-tabs {
		top: 0;
		> li {
			display: block;
			float: none;
		}
	}
	.box.border.orange .box-tabs .nav-tabs ,
	.box.border.blue .box-tabs .nav-tabs,
	.box.border.red .box-tabs .nav-tabs,
	.box.border.green .box-tabs .nav-tabs,
	.box.border.primary .box-tabs .nav-tabs,
	.box.border.pink .box-tabs .nav-tabs,
	.box.border.purple .box-tabs .nav-tabs,
	.box.border.inverse .box-tabs .nav-tabs{
		top: 0;
		> li {
			display: block;
			float: none;
			> a {
				color: #666666;
			}
			&.active a {
				font-weight: 600;
				color: #555555;
				&:before {
					color: #333;
					display: inline;
					font-size: 16px;
					font-family: FontAwesome;
					height: auto;
					content: "\f00c";
					font-weight: 300;
					text-shadow:none;
					padding: 0 5px;
				}
			}
		}
	}
	/* HUBSPOT MESSENGER */
	ul.messenger.messenger-fixed.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-left {
		width: 50% !important;
	}
	/* WIZARDS AND VALIDATION */
	.form-wizard .nav-justified > li > a {
		text-align: left;
	}
	/* CHAT WIDGET */
	.chat-widget {
		right: 5px;
		width: 85%;
	}
	/* PRICING */
	.pricing_table {
		margin: 0 -15px 10px -15px;
	}
	/* INVOICE */
	.invoice-header {
		margin-bottom: 20px;
		.float-left, .float-right {
			display: block;
			float: none !important;
		}
	}
	.amount {
		margin-right: 0;
	}
	/* ERROR 404 & 500 */
	.not-found {
		.error {
			right: 30%;
		}
		.error-500 {
			right: 30%;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
	body {
		overflow-x: hidden;
	}
	#navbar-left {
		.name {
			display: none;
		}
	}
}