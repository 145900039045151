@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*$white:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #d9534f;
$orange:  #f0ad4e;
$yellow:  #ffc107;
$green:   #A8BC7B;
$teal:    #20c997;
$cyan:    #70AFC4;*/
@import './common/googleFont.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~animate.css';
@import '~jquery.uniform/themes/default/css/uniform.default.min.css';
:root {
  --blue: #2d6cb4;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #ee7599;
  --red: #e83e71;
  --orange: #f8ab41;
  --yellow: #fbce3f;
  --green: #88C425;
  --teal: #20c997;
  --cyan: #45B0F0;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2d6cb4;
  --secondary: #6c757d;
  --success: #88C425;
  --info: #45B0F0;
  --warning: #f8ab41;
  --danger: #e83e71;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.428571429;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2d6cb4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1e4777;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999999;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #c7254e;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #333333;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: transparent;
}
.table th,
.table td {
  padding: 8px;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}
.table tbody + tbody {
  border-top: 2px solid #ddd;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #ddd;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c4d6ea;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #92b3d8;
}

.table-hover .table-primary:hover {
  background-color: #b1c9e3;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b1c9e3;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #deeec2;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c1e08e;
}

.table-hover .table-success:hover {
  background-color: #d3e8ae;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d3e8ae;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #cbe9fb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #9ed6f7;
}

.table-hover .table-info:hover {
  background-color: #b3dff9;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3dff9;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fde7ca;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fbd39c;
}

.table-hover .table-warning:hover {
  background-color: #fcdcb1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fcdcb1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9c9d7;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f39bb5;
}

.table-hover .table-danger:hover {
  background-color: #f6b2c6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f6b2c6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ddd;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555555;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #555555;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #81ade0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #555555;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #999999;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #88C425;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(136, 196, 37, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #88C425;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2388C425' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #88C425;
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #88C425;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2388C425' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #88C425;
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #88C425;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #88C425;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #88C425;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #a1db41;
  background-color: #a1db41;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #88C425;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #88C425;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #88C425;
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e83e71;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(232, 62, 113, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e83e71;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e83e71' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e83e71' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e83e71;
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e83e71;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e83e71' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e83e71' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e83e71;
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e83e71;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e83e71;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e83e71;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ed6c93;
  background-color: #ed6c93;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e83e71;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e83e71;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e83e71;
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: normal;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #2d6cb4;
  border-color: #2d6cb4;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #255a95;
  border-color: #23548b;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #255a95;
  border-color: #23548b;
  box-shadow: 0 0 0 0.2rem rgba(77, 130, 191, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #2d6cb4;
  border-color: #2d6cb4;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #23548b;
  border-color: #204d81;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 130, 191, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #88C425;
  border-color: #88C425;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #72a41f;
  border-color: #6a991d;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #72a41f;
  border-color: #6a991d;
  box-shadow: 0 0 0 0.2rem rgba(121, 172, 38, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #88C425;
  border-color: #88C425;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #6a991d;
  border-color: #638e1b;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 172, 38, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #45B0F0;
  border-color: #45B0F0;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #22a1ed;
  border-color: #169cec;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #22a1ed;
  border-color: #169cec;
  box-shadow: 0 0 0 0.2rem rgba(64, 155, 210, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #45B0F0;
  border-color: #45B0F0;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #169cec;
  border-color: #1295e3;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 155, 210, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #f8ab41;
  border-color: #f8ab41;
}
.btn-warning:hover {
  color: #212529;
  background-color: #f79b1c;
  border-color: #f69510;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #f79b1c;
  border-color: #f69510;
  box-shadow: 0 0 0 0.2rem rgba(216, 151, 61, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #f8ab41;
  border-color: #f8ab41;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #f69510;
  border-color: #f08f09;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 151, 61, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #e83e71;
  border-color: #e83e71;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #e41c58;
  border-color: #d91a53;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #e41c58;
  border-color: #d91a53;
  box-shadow: 0 0 0 0.2rem rgba(235, 91, 134, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #e83e71;
  border-color: #e83e71;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #d91a53;
  border-color: #ce194f;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 91, 134, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #2d6cb4;
  border-color: #2d6cb4;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #2d6cb4;
  border-color: #2d6cb4;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2d6cb4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #2d6cb4;
  border-color: #2d6cb4;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #88C425;
  border-color: #88C425;
}
.btn-outline-success:hover {
  color: #212529;
  background-color: #88C425;
  border-color: #88C425;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #88C425;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #88C425;
  border-color: #88C425;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.5);
}

.btn-outline-info {
  color: #45B0F0;
  border-color: #45B0F0;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #45B0F0;
  border-color: #45B0F0;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 176, 240, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #45B0F0;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #45B0F0;
  border-color: #45B0F0;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 176, 240, 0.5);
}

.btn-outline-warning {
  color: #f8ab41;
  border-color: #f8ab41;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #f8ab41;
  border-color: #f8ab41;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 171, 65, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f8ab41;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #f8ab41;
  border-color: #f8ab41;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 171, 65, 0.5);
}

.btn-outline-danger {
  color: #e83e71;
  border-color: #e83e71;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #e83e71;
  border-color: #e83e71;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e83e71;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #e83e71;
  border-color: #e83e71;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2d6cb4;
  text-decoration: none;
}
.btn-link:hover {
  color: #1e4777;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #999999;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #333333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #2d6cb4;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #2d6cb4;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #999999;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #999999;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #333333;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 2.0000000006rem;
  padding-left: 1.9rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.4rem;
  height: 1.7000000003rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #2d6cb4;
  background-color: #2d6cb4;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #81ade0;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #aac8ea;
  border-color: #aac8ea;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.3000000003rem;
  left: -1.9rem;
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.3000000003rem;
  left: -1.9rem;
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #2d6cb4;
  background-color: #2d6cb4;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(45, 108, 180, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(45, 108, 180, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(45, 108, 180, 0.5);
}

.custom-switch {
  padding-left: 2.95rem;
}
.custom-switch .custom-control-label::before {
  left: -2.95rem;
  width: 2.45rem;
  pointer-events: all;
  border-radius: 0.7rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.3000000003rem + 2px);
  left: calc(-2.95rem + 2px);
  width: calc(1.4rem - 4px);
  height: calc(1.4rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.7rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(1.05rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(45, 108, 180, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #81ade0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.custom-select:focus::-ms-value {
  color: #555555;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #81ade0;
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #2d6cb4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #aac8ea;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2d6cb4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #aac8ea;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #2d6cb4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #aac8ea;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #eeeeee;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #2d6cb4;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #F5F5F5;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #999999;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2d6cb4;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1e4777;
  text-decoration: none;
  background-color: #eeeeee;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2d6cb4;
  border-color: #2d6cb4;
}
.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #2d6cb4;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #23548b;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 108, 180, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #88C425;
}
a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #6a991d;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(136, 196, 37, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #45B0F0;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #169cec;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(69, 176, 240, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #f8ab41;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #f69510;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 171, 65, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #e83e71;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #d91a53;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 113, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 30px 15px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eeeeee;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 60px 30px;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #17385e;
  background-color: #d5e2f0;
  border-color: #c4d6ea;
}
.alert-primary hr {
  border-top-color: #b1c9e3;
}
.alert-primary .alert-link {
  color: #0d2035;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #476613;
  background-color: #e7f3d3;
  border-color: #deeec2;
}
.alert-success hr {
  border-top-color: #d3e8ae;
}
.alert-success .alert-link {
  color: #293b0b;
}

.alert-info {
  color: #245c7d;
  background-color: #daeffc;
  border-color: #cbe9fb;
}
.alert-info hr {
  border-top-color: #b3dff9;
}
.alert-info .alert-link {
  color: #193f55;
}

.alert-warning {
  color: #815922;
  background-color: #feeed9;
  border-color: #fde7ca;
}
.alert-warning hr {
  border-top-color: #fcdcb1;
}
.alert-warning .alert-link {
  color: #593d17;
}

.alert-danger {
  color: #79203b;
  background-color: #fad8e3;
  border-color: #f9c9d7;
}
.alert-danger hr {
  border-top-color: #f6b2c6;
}
.alert-danger .alert-link {
  color: #511527;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2d6cb4;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f5f5f5;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2d6cb4;
  border-color: #2d6cb4;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #17385e;
  background-color: #c4d6ea;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #17385e;
  background-color: #b1c9e3;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #17385e;
  border-color: #17385e;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #476613;
  background-color: #deeec2;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #476613;
  background-color: #d3e8ae;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #476613;
  border-color: #476613;
}

.list-group-item-info {
  color: #245c7d;
  background-color: #cbe9fb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #245c7d;
  background-color: #b3dff9;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #245c7d;
  border-color: #245c7d;
}

.list-group-item-warning {
  color: #815922;
  background-color: #fde7ca;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #815922;
  background-color: #fcdcb1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #815922;
  border-color: #815922;
}

.list-group-item-danger {
  color: #79203b;
  background-color: #f9c9d7;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #79203b;
  background-color: #f6b2c6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #79203b;
  border-color: #79203b;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e5e5e5;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.428571429;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1.75rem;
  border-top: 1px solid #e5e5e5;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571429;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 5px;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 2.5px 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 5px;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 2.5px 0.4rem 2.5px 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 2.5px 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 5px;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 2.5px 0 2.5px 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: block;
  max-width: 276px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.428571429;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 10px;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 5px 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 5px 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 10px;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 5px 0.5rem 5px 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 5px 0.5rem 5px 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 5px 0.5rem 5px;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 5px 0.5rem 5px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 10px;
  margin-left: -5px;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 10px;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 5px 0 5px 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 5px 0 5px 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #2d6cb4 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #23548b !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #88C425 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #6a991d !important;
}

.bg-info {
  background-color: #45B0F0 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #169cec !important;
}

.bg-warning {
  background-color: #f8ab41 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f69510 !important;
}

.bg-danger {
  background-color: #e83e71 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d91a53 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #2d6cb4 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #88C425 !important;
}

.border-info {
  border-color: #45B0F0 !important;
}

.border-warning {
  border-color: #f8ab41 !important;
}

.border-danger {
  border-color: #e83e71 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #2d6cb4 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1e4777 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #88C425 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #5b8419 !important;
}

.text-info {
  color: #45B0F0 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #118dd7 !important;
}

.text-warning {
  color: #f8ab41 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #e48808 !important;
}

.text-danger {
  color: #e83e71 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #c2174b !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #999999 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #ddd;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #ddd;
  }
}
/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url("../fonts/lato-v16-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato-v16-latin-regular.eot") format("embedded-opentype"), url("../fonts/lato-v16-latin-regular.woff2") format("woff2"), url("../fonts/lato-v16-latin-regular.woff") format("woff"), url("../fonts/lato-v16-latin-regular.ttf") format("truetype"), url("../fonts/lato-v16-latin-regular.svg") format("svg");
  /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url("../fonts/lato-v16-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato-v16-latin-700.eot") format("embedded-opentype"), url("../fonts/lato-v16-latin-700.woff2") format("woff2"), url("../fonts/lato-v16-latin-700.woff") format("woff"), url("../fonts/lato-v16-latin-700.ttf") format("truetype"), url("../fonts/lato-v16-latin-700.svg") format("svg");
  /* Legacy iOS */
}
/* Colors */
/* Primary colors */
/*-----------------------------------------------------------------------------------*/
/*	Homepage
/*-----------------------------------------------------------------------------------*/
/* Override */
html, body {
  height: 100%;
}

body {
  background: none repeat scroll 0 0 #ffffff;
  border: 0 none;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #555555;
  /* overflow-x: hidden; */
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300 !important;
}

label {
  font-weight: 600;
}

.alert {
  padding: 15px;
}

.float-right {
  float: right;
}

.float-left {
  float: right;
}

.alert-info {
  color: #3a87ad !important;
}

/* Dotted outline removal */
a:active, a:focus {
  outline-style: none;
  -moz-outline-style: none;
}

* {
  outline: medium none !important;
}

a:focus, a.focus {
  box-shadow: 0 0 0 0 transparent !important;
}

.nav-bar-admin {
  position: absolute;
  right: 0px;
  top: -3px;
  direction: rtl;
  font-size: 15px;
}

.nav-bar-admin .nav-link {
  padding: 0.9em 1rem;
}

/* Bootstrap extra */
.hidden-inline-mobile {
  display: inline !important;
}

@media (max-width: 768px) {
  .hidden-inline-mobile {
    display: none !important;
  }
}
/* Font weight */
.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

/* Font size */
.font-11 {
  font-size: 11px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

/* Font colors */
.text-dark {
  color: #555555;
}

.text-purple {
  color: #A696CE;
}

.text-pink {
  color: #DB5E8C;
}

.text-yellow {
  color: #FCD76A;
}

.text-green {
  color: #88C425;
}

.text-white {
  color: #fff;
}

/* Icons */
.fa-1x {
  font-size: 1.5em;
}

/* Buttons */
.btn {
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.btn-inverse {
  color: #212529;
  background-color: #fff;
  border-color: #555555;
}
.btn-inverse:hover {
  color: #ffffff;
  background-color: #484848;
  border-color: #3c3c3c;
}
.btn-inverse:focus, .btn-inverse.focus {
  color: #ffffff;
  background-color: #484848;
  border-color: #3c3c3c;
  box-shadow: 0 0 0 0.2rem rgba(77, 78, 78, 0.5);
}
.btn-inverse.disabled, .btn-inverse:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #555555;
}
.btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active, .show > .btn-inverse.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #353535;
}
.btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus, .show > .btn-inverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 78, 78, 0.5);
}
.btn-inverse .caret {
  border-top-color: #FFFFFF;
}

.btn-purple {
  color: #212529;
  background-color: #fff;
  border-color: #A696CE;
}
.btn-purple:hover {
  color: #ffffff;
  background-color: #9785c6;
  border-color: #8973be;
}
.btn-purple:focus, .btn-purple.focus {
  color: #ffffff;
  background-color: #9785c6;
  border-color: #8973be;
  box-shadow: 0 0 0 0.2rem rgba(146, 133, 181, 0.5);
}
.btn-purple.disabled, .btn-purple:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #A696CE;
}
.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active, .show > .btn-purple.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #816bba;
}
.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 133, 181, 0.5);
}
.btn-purple .caret {
  border-top-color: #FFFFFF;
}

.btn-pink {
  color: #212529;
  background-color: #fff;
  border-color: #DB5E8C;
}
.btn-pink:hover {
  color: #ffffff;
  background-color: #d6497d;
  border-color: #d2346e;
}
.btn-pink:focus, .btn-pink.focus {
  color: #ffffff;
  background-color: #d6497d;
  border-color: #d2346e;
  box-shadow: 0 0 0 0.2rem rgba(191, 85, 125, 0.5);
}
.btn-pink.disabled, .btn-pink:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #DB5E8C;
}
.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active, .show > .btn-pink.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #cc2e68;
}
.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus, .show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 85, 125, 0.5);
}
.btn-pink .caret {
  border-top-color: #FFFFFF;
}

.btn-grey {
  color: #212529;
  background-color: #fff;
  border-color: #898989;
}
.btn-grey:hover {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #707070;
}
.btn-grey:focus, .btn-grey.focus {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #707070;
  box-shadow: 0 0 0 0.2rem rgba(121, 122, 123, 0.5);
}
.btn-grey.disabled, .btn-grey:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #898989;
}
.btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active, .show > .btn-grey.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: dimgray;
}
.btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus, .show > .btn-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 122, 123, 0.5);
}

.btn-light-grey {
  color: #ffffff;
  background-color: #898989;
  border-color: #E6E6E6;
}
.btn-light-grey:hover {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #cdcdcd;
}
.btn-light-grey:focus, .btn-light-grey.focus {
  color: #212529;
  background-color: #d9d9d9;
  border-color: #cdcdcd;
  box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5);
}
.btn-light-grey.disabled, .btn-light-grey:disabled {
  color: #ffffff;
  background-color: #898989;
  border-color: #E6E6E6;
}
.btn-light-grey:not(:disabled):not(.disabled):active, .btn-light-grey:not(:disabled):not(.disabled).active, .show > .btn-light-grey.dropdown-toggle {
  color: #ffffff;
  background-color: #707070;
  border-color: #c6c6c6;
}
.btn-light-grey:not(:disabled):not(.disabled):active:focus, .btn-light-grey:not(:disabled):not(.disabled).active:focus, .show > .btn-light-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5);
}

.btn-yellow {
  color: #ffffff;
  background-color: #654c02;
  border-color: #FCD76A;
}
.btn-yellow:hover {
  color: #212529;
  background-color: #fbd051;
  border-color: #fbca38;
}
.btn-yellow:focus, .btn-yellow.focus {
  color: #212529;
  background-color: #fbd051;
  border-color: #fbca38;
  box-shadow: 0 0 0 0.2rem rgba(252, 221, 128, 0.5);
}
.btn-yellow.disabled, .btn-yellow:disabled {
  color: #ffffff;
  background-color: #654c02;
  border-color: #FCD76A;
}
.btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active, .show > .btn-yellow.dropdown-toggle {
  color: #ffffff;
  background-color: #332601;
  border-color: #fbc62c;
}
.btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 221, 128, 0.5);
}

.btn-icon {
  margin: 10px 0;
  padding: 10px;
  transition: all 0.3s ease 0s;
}

.btn-icon:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0s;
}

.btn-icon:active {
  transition: none 0s ease 0s;
}

.btn-icon i {
  margin: 0;
}

.btn .label {
  border-radius: 12px 12px 12px 12px;
  border-style: solid;
  border-width: 0;
  box-shadow: none;
  color: #FFFFFF !important;
  font-size: 11px !important;
  font-weight: 300;
  padding: 3px 7px;
  position: absolute;
  text-shadow: none;
  top: 1px;
}
.btn .label.label-right {
  right: 7px;
}
.btn .label.label-left {
  left: 7px;
}

.input-block-level {
  display: block;
  width: 100%;
}

/* Container */
.container {
  width: 100% !important;
}

/* Dividers */
.divide-10 {
  height: 10px;
  width: 100%;
}

.divide-12 {
  height: 12px;
  width: 100%;
}

.divide-20 {
  height: 20px;
  width: 100%;
}

.divide-40 {
  height: 40px;
  width: 100%;
}

.divide-100 {
  height: 100px;
  width: 100%;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-left-50 {
  margin-left: 50px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.separator {
  height: 1px;
  border-bottom: 1px solid #FFFFFF !important;
  border-top: 1px solid #E7E7E7 !important;
  color: #555555 !important;
  margin: 10px 0 30px;
}

.fileinput-preview img {
  max-width: 100%;
  max-height: 100%;
}

#header a:focus {
  background-color: transparent !important;
}

.sidebar-menu > ul > li > a:focus {
  background-color: transparent !important;
}

#header {
  padding: 0 !important;
}

#header .nav-link {
  padding: 0.95rem 1rem !important;
}

.nav-header-right {
  justify-content: right;
  display: flex;
  width: 100%;
}

#header-user .nav-link {
  padding: 10px 10px 11px !important;
}

#header .skins a:hover {
  background-color: #fff !important;
}

#header-exercices select.form-control {
  max-width: 260px;
}

#header-budgetTypes .dropdown-item:hover, #header-budgetTypes .dropdown-item:focus {
  background-color: transparent;
}

#header-budgetTypes .dropdown-item {
  padding: 0.25rem 0.5rem;
}

.card .bg-primary .card-title, .card .bg-warning .card-title, .card .bg-success .card-title, .card .bg-danger .card-title, .card .bg-info .card-title, .card .bg-secondary .card-title {
  color: #fff !important;
}

.clear {
  clear: both;
}

/* Badges */
.badge-green {
  background-color: #88C425;
}

.badge-orange {
  background-color: #f0ad4e;
}

.badge-red {
  background-color: #ee7599;
}

.badge-blue {
  background-color: #45B0F0;
}

/* Scroller */
.scroller {
  margin: 0 !important;
  padding: 0 12px 0 0 !important;
}

/* Content */
#main-content {
  margin-left: 250px;
}

/* Content */
#content {
  background-color: #F5F6F6;
  margin-top: 0;
  min-height: 800px;
  border-left: 1px solid #CDD2D2;
  padding-bottom: 30px;
  /* Page Header */
  /* Breadcrumbs */
}
#content .page-header {
  background: none repeat scroll 0 0 #FFFFFF;
  border-bottom-color: #CDD2D2;
  color: #000000;
  margin: 0 -15px 30px;
  min-height: 85px;
  overflow: hidden;
  padding: 0 20px;
  position: relative;
}
#content .page-header .description {
  color: #888888;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  margin-bottom: 5px;
}
#content .content-title {
  color: #666666;
  display: block;
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1px;
  margin: 5px 0;
  padding: 0;
}
#content .breadcrumb {
  margin-bottom: 0;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 14px;
  color: #333;
  background-color: #FFFFFF;
}
#content .breadcrumb a {
  text-shadow: none;
}
#content .breadcrumb > li > i.fa-angle-right {
  color: #333;
  /*display: inline;
  font-size: 16px;
  font-family: "Font Awesome 5 Free";
  height: auto;
  content: "\f105";
  font-weight: 300;
  text-shadow:none;*/
  padding-left: 5px;
}

.date-range {
  margin-top: 20px;
}
.date-range .btn-group .btn {
  margin-top: -8px;
  padding: 8px;
}
.date-range .reportrange {
  margin-right: 0px;
  margin-top: -8px;
  padding: 10px;
  cursor: pointer;
  min-width: 275x;
  color: #fff;
  border-radius: 0 4px 4px 0;
  background-color: #88C425;
}
.date-range .reportrange span {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
  font-weight: normal;
}
.date-range .reportrange .fa-calendar {
  text-transform: normal;
  color: #fff;
  margin-top: 0px;
  font-size: 14px;
}
.date-range .reportrange .fa-angle-down {
  color: #fff;
  font-size: 16px;
}

/* Patterns */
.pattern {
  background-image: url("../images/pattern.png");
}

.dots {
  background-image: url("../images/dots.png");
}

/*-----------------------------------------------------------------------------------*/
/*	Grids and Box
/*-----------------------------------------------------------------------------------*/
.box {
  clear: both;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
}
.box .box-title {
  margin-bottom: 15px;
  border-bottom: 1px solid #C4C4C4;
  min-height: 30px;
}
.box .box-title h4 {
  font-family: "Open Sans";
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 7px;
  color: #555555;
}
.box .box-title h4 i {
  margin-right: 10px;
  color: #666;
}
.box .box-title .tools {
  display: inline-block;
  padding: 0;
  margin-bottom: 7px;
  float: right;
  clear: both;
  min-height: 30px;
}
.box .box-title .tools > a {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #C4C4C4;
  margin-right: 5px;
}
.box .box-title .tools > a :hover {
  color: #ababab;
}
.box .box-title .tools .label {
  margin-right: 5px;
}
.box .box-title .items {
  display: inline-block;
  padding: 0;
  margin-bottom: 7px;
  margin-top: 7px;
  float: right;
  width: 40%;
  clear: both;
}
.box .box-title .items .progress {
  height: 7px !important;
}
.box .toolbox {
  padding: 5px;
  background-color: #2d6cb4;
  border-bottom: 1px solid #C4C4C4;
}
.box .toolbox.bottom {
  padding: 5px;
  background-color: #2d6cb4;
  border-top: 1px solid #C4C4C4;
  border-radius: 0 0 4px 4px;
  border-bottom: none;
}
.box .box-body.bg {
  border-radius: 0 0 4px 4px;
  padding: 10px;
  background-color: #FFFFFF;
  border-left: 1px solid #C4C4C4;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  margin-top: -15px;
}

/* Sortable */
.box-placeholder {
  border: 1px dashed #C4C4C4;
  background-color: #eaeaea;
  border-radius: 4px;
}

/* Colors */
.box.border.orange {
  border: 1px solid #eea236;
}
.box.border.orange > .box-title {
  color: #FFFFFF !important;
  background-color: #f0ad4e;
  border-bottom: 1px solid #eea236;
}
.box.border.orange > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.orange > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.orange > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.orange > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.pink {
  border: 1px solid #d6497d;
}
.box.border.pink > .box-title {
  color: #FFFFFF !important;
  background-color: #DB5E8C;
  border-bottom: 1px solid #d6497d;
}
.box.border.pink > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.pink > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.pink > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.pink > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.purple {
  border: 1px solid #9785c6;
}
.box.border.purple > .box-title {
  color: #FFFFFF !important;
  background-color: #A696CE;
  border-bottom: 1px solid #9785c6;
}
.box.border.purple > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.purple > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.purple > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.purple > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.inverse {
  border: 1px solid #484848;
}
.box.border.inverse > .box-title {
  color: #FFFFFF !important;
  background-color: #555555;
  border-bottom: 1px solid #484848;
}
.box.border.inverse > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.inverse > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.inverse > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.inverse > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.blue {
  border: 1px solid #2da6ee;
}
.box.border.blue > .box-title {
  color: #FFFFFF !important;
  background-color: #45B0F0;
  border-bottom: 1px solid #2da6ee;
}
.box.border.blue > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.blue > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.blue > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.blue > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.red {
  border: 1px solid #eb5e88;
}
.box.border.red > .box-title {
  color: #FFFFFF !important;
  background-color: #ee7599;
  border-bottom: 1px solid #eb5e88;
}
.box.border.red > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.red > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.red > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.red > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.green {
  border: 1px solid #79af21;
}
.box.border.green > .box-title {
  color: #FFFFFF !important;
  background-color: #88C425;
  border-bottom: 1px solid #79af21;
}
.box.border.green > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.green > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.green > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.green > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

.box.border.primary {
  border: 1px solid #2860a0;
}
.box.border.primary > .box-title {
  color: #FFFFFF !important;
  background-color: #2d6cb4;
  border-bottom: 1px solid #2860a0;
}
.box.border.primary > .box-title h4 {
  color: #FFFFFF !important;
}
.box.border.primary > .box-title h4 i {
  color: #FFFFFF !important;
}
.box.border.primary > .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.border.primary > .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}

/* Box border */
.box.border, .box.solid {
  border: 1px solid #C4C4C4;
  border-radius: 4px;
}
.box.border > .box-title, .box.solid > .box-title {
  padding: 8px 10px 2px;
  border-bottom: 1px solid #C4C4C4;
  min-height: 30px;
  background-color: #23548b;
  /* border-radius: 4px 4px 0 0; */
  margin-bottom: 0;
}
.box.border > .box-title h4, .box.solid > .box-title h4 {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-bottom: 7px;
}
.box.border > .box-title h4 i, .box.solid > .box-title h4 i {
  margin-right: 10px;
  color: #666;
}
.box.border > .box-title .tools, .box.solid > .box-title .tools {
  display: inline-block;
  padding: 0;
  margin-bottom: 7px;
  float: right;
  clear: both;
  min-height: 30px;
}
.box.border > .box-title .tools > a, .box.solid > .box-title .tools > a {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  color: #ababab;
  margin-right: 5px;
}
.box.border > .box-title .tools > a :hover, .box.solid > .box-title .tools > a :hover {
  color: #919191;
}
.box.border .box-body, .box.solid .box-body {
  border-radius: 0 0 4px 4px;
  padding: 10px;
  background-color: #FFFFFF;
}
.box.border .box-body.big, .box.solid .box-body.big {
  padding: 30px;
}
.box.border .box-title.big, .box.solid .box-title.big {
  padding: 15px 10px 7px;
}
.box.border .box-title.small, .box.solid .box-title.small {
  padding: 5px 10px 3px;
}
.box.border .box-title.small h4, .box.solid .box-title.small h4 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3px;
}
.box.border .box-title.small .tools > a, .box.solid .box-title.small .tools > a {
  font-size: 12px;
  line-height: 12px;
}

.box.border.lite .box-title {
  background-color: #2d6cb4;
}
.box.border.lite .box-title h4 {
  color: #6f6f6f;
}

.box.solid.blue {
  border: 1px solid #2860a0;
}
.box.solid.blue .box-title {
  color: #FFFFFF !important;
  background-color: #2d6cb4 !important;
  border-bottom: 1px solid #2860a0;
}
.box.solid.blue .box-title h4 {
  color: #FFFFFF !important;
}
.box.solid.blue .box-title h4 i {
  color: #FFFFFF !important;
}
.box.solid.blue .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.solid.blue .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}
.box.solid.blue .box-body {
  color: #FFFFFF;
  background-color: #2d6cb4 !important;
}

.box.solid.grey {
  border: 1px solid #626262;
}
.box.solid.grey .box-title {
  color: #FFFFFF !important;
  background-color: #6f6f6f !important;
  border-bottom: 1px solid #626262;
}
.box.solid.grey .box-title h4 {
  color: #FFFFFF !important;
}
.box.solid.grey .box-title h4 i {
  color: #FFFFFF !important;
}
.box.solid.grey .box-title .tools > a {
  color: #F4F4F4 !important;
}
.box.solid.grey .box-title .tools > a :hover {
  color: #F4F4F4 !important;
  opacity: 0.6 !important;
}
.box.solid.grey .box-body {
  color: #FFFFFF;
  background-color: #6f6f6f !important;
}

.box:after, .box:before {
  display: table;
  color: "";
}

/* Misc */
.list-group-header {
  background-color: #F9F9F9;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  color: #6f6f6f;
}

/*-----------------------------------------------------------------------------------*/
/*	Elements
/*-----------------------------------------------------------------------------------*/
.example-modal .modal {
  bottom: auto;
  display: inline;
  margin: 0 auto;
  left: auto;
  position: relative;
  right: auto;
  top: auto;
  z-index: 1;
  width: 100%;
}
.example-modal .modal .modal-dialog {
  width: 100%;
}

.center {
  text-align: center;
}

#themes .title {
  margin-right: 8px;
}
#themes .btn.active .tick:before {
  float: left;
  margin-top: 0px;
  margin-right: 5px;
  display: inline;
  font-size: 13px;
  font-family: "Font Awesome 5 Free";
  height: auto;
  content: "\f00c";
  font-weight: 300;
  text-shadow: none;
}

#messenger .btn i {
  font-size: 18px;
}

div.hint {
  background-color: #F8F8F8;
  border-radius: 5px;
  color: #333;
  display: inline-block;
  height: 27px;
  text-align: center;
  width: 135px;
  vertical-align: middle;
  margin-top: 5px;
  font-weight: 600;
}

/* Labels and Arrows */
.label.arrow-out, .label.arrow-in {
  border-radius: 0 !important;
  position: relative;
  z-index: 1;
}

.label.arrow-out:before, .label.arrow-in:before {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: rgba(0, 0, 0, 0) #ABBAC3 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-image: none;
  border-style: solid;
  border-width: 1px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  z-index: -1;
}

.label.arrow-in:before {
  border-bottom-color: #ABBAC3;
  border-left-color: rgba(0, 0, 0, 0) !important;
  border-right-color: #ABBAC3;
  border-top-color: #ABBAC3;
}

.label.arrow-out-right, .label.arrow-in-right {
  position: relative;
  z-index: 1;
}

.label.arrow-out-right:after, .label.arrow-in-right:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ABBAC3;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  z-index: -1;
}

.label.arrow-in-right:after {
  border-bottom-color: #ABBAC3;
  border-left-color: #ABBAC3;
  border-right-color: rgba(0, 0, 0, 0) !important;
  border-top-color: #ABBAC3;
}

.label-info.arrow-out:before {
  border-right-color: #45B0F0;
}

.label-info.arrow-in:before {
  border-color: #45B0F0;
}

.label-info.arrow-out-right:after {
  border-left-color: #45B0F0;
}

.label-info.arrow-in-right:after {
  border-color: #45B0F0;
}

.label-primary.arrow-out:before {
  border-right-color: #2d6cb4;
}

.label-primary.arrow-in:before {
  border-color: #2d6cb4;
}

.label-primary.arrow-out-right:after {
  border-left-color: #2d6cb4;
}

.label-primary.arrow-in-right:after {
  border-color: #2d6cb4;
}

.label-success.arrow-out:before {
  border-right-color: #88C425;
}

.label-success.arrow-in:before {
  border-color: #88C425;
}

.label-success.arrow-out-right:after {
  border-left-color: #88C425;
}

.label-success.arrow-in-right:after {
  border-color: #88C425;
}

.label-warning.arrow-out:before {
  border-right-color: #f0ad4e;
}

.label-warning.arrow-in:before {
  border-color: #f0ad4e;
}

.label-warning.arrow-out-right:after {
  border-left-color: #f0ad4e;
}

.label-warning.arrow-in-right:after {
  border-color: #f0ad4e;
}

.label-danger.arrow-out:before {
  border-right-color: #ee7599;
}

.label-danger.arrow-in:before {
  border-color: #ee7599;
}

.label-danger.arrow-out-right:after {
  border-left-color: #ee7599;
}

.label-danger.arrow-in-right:after {
  border-color: #ee7599;
}

.label-inverse.arrow-out:before {
  border-right-color: #555555;
}

.label-inverse.arrow-in:before {
  border-color: #555555;
}

.label-inverse.arrow-out-right:after {
  border-left-color: #555555;
}

.label-inverse.arrow-in-right:after {
  border-color: #555555;
}

.label {
  height: 20px;
  line-height: 1.15;
}

.label.arrow-out {
  margin-left: 5px;
}

.label.arrow-out:before {
  border-width: 9px 5px 10px;
  left: -10px;
}

.label.arrow-in {
  margin-left: 5px;
}

.label.arrow-in:before {
  border-width: 9px 5px 10px;
  left: -5px;
}

.label.arrow-out-right {
  margin-right: 5px;
}

.label.arrow-out-right:after {
  border-width: 9px 5px 10px;
  right: -10px;
}

.label.arrow-in-right {
  margin-right: 5px;
}

.label.arrow-in-right:after {
  border-width: 9px 5px 10px;
  right: -5px;
}

.label > span, .label > [class*=fa-] {
  line-height: 1;
}

.ui-slider {
  margin-bottom: 8px;
  margin-top: 10px;
}

.slider-eq > span {
  float: left;
  height: 125px;
  margin: 15px;
}

/* Sliers & Progress bar */
.slider {
  background: none repeat scroll 0 0 #E4E6EB !important;
  border: 0 none !important;
  border-radius: 50em 50em 50em 50em !important;
  position: relative;
  z-index: 10;
}

.ui-slider-horizontal {
  height: 0.7em !important;
}

.ui-slider-vertical {
  width: 0.7em !important;
}

.progress {
  background: none repeat scroll 0 0 #E4E6EB !important;
  border: 0 none !important;
  border-radius: 50em 50em 50em 50em !important;
  box-shadow: none;
  height: 14px !important;
  margin-top: -2px;
  position: relative;
}
.progress.progress-sm {
  height: 9px !important;
}
.progress.progress-lg {
  height: 17px !important;
}

.progress .ui-progressbar {
  border-radius: 50em 50em 50em 50em !important;
}

.progress .ui-progressbar-value {
  border: 0 none;
  border-radius: 50em 50em 50em 50em;
  box-shadow: none;
  height: 100%;
  left: 1px;
  position: absolute;
  top: 1px;
}

/* Animated progress */
.pbar .ui-progressbar-value {
  display: block !important;
}

.pbar.progress {
  overflow: hidden;
  margin-bottom: 5px;
  margin-top: 3px;
}

.percent {
  position: relative;
  text-align: right;
}

.elapsed {
  position: relative;
  text-align: right;
}

/* Slider & Progressbar colors */
.slider-blue .ui-slider-range, .progress-blue .ui-progressbar-value {
  background: none repeat scroll 0 0 #45B0F0;
}

.slider-green .ui-slider-range, .progress-green .ui-progressbar-value {
  background: none repeat scroll 0 0 #88C425;
}

.slider-pink .ui-slider-range, .progress-pink .ui-progressbar-value {
  background: none repeat scroll 0 0 #DB5E8C;
}

.slider-orange .ui-slider-range, .progress-orange .ui-progressbar-value {
  background: none repeat scroll 0 0 #f0ad4e;
}

.slider-red .ui-slider-range, .progress-red .ui-progressbar-value {
  background: none repeat scroll 0 0 #ee7599;
}

.slider-yellow .ui-slider-range, .progress-yellow .ui-progressbar-value {
  background: none repeat scroll 0 0 #FCD76A;
}

.slider-dark .ui-slider-range, .progress-dark .ui-progressbar-value {
  background: none repeat scroll 0 0 #555555;
}

/* Progress bar colors */
.progress-bar-yellow {
  background-color: #FCD76A;
}

.progress-bar-pink {
  background-color: #DB5E8C;
}

.progress-bar-dark {
  background-color: #555555;
}

/* Tabs and Accordions */
.box .header-tabs .nav-tabs {
  border-bottom: 0 none;
  margin-right: 0;
  overflow: hidden;
  position: relative;
  top: -43px;
}
.box .header-tabs .nav-tabs > li {
  float: right;
  margin-left: 1px;
}
.box .header-tabs .nav-tabs > li > a {
  position: relative;
  background: #eee;
  /* background-image: linear-gradient(to bottom, #fff, #ddd);  */
  padding: 7px 15px;
  float: left;
  text-decoration: none;
  color: #444;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px 0 0 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  margin-right: 15px;
  border-top: 1px solid #d0d0d0;
}
.box .header-tabs .nav-tabs > li > a i {
  margin-right: 5px;
}
.box .header-tabs .nav-tabs > li > a:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -5em;
  bottom: 0;
  width: 1em;
  background: #eee;
  /* background-image: linear-gradient(to bottom, #fff, #ddd);  */
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
  border-top: 1px solid #d0d0d0;
  transform: skew(10deg);
  -webkit-transform: skew(10deg);
  border-radius: 0 5px 0 0;
}
.box .header-tabs .nav-tabs > li > a:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -10px;
  bottom: 0;
  width: 1em;
  background: #eee;
  /* background-image: linear-gradient(to bottom, #fff, #ddd);  */
  border-left: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
  transform: skew(170deg);
  -webkit-transform: skew(170deg);
  border-radius: 5px 0 0 0;
}
.box .header-tabs .nav-tabs > li.active a, .box .header-tabs .nav-tabs > li.active a:after, .box .header-tabs .nav-tabs > li.active a:before {
  background: #fff;
  z-index: 3;
}

/* Header Tabs content */
.box .header-tabs .tab-content {
  margin-top: -30px;
}

/* Left, Right, Below tabs - removed from Bootstrap 3 */
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
}

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}

.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #ddd #ddd #ddd;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

/* Accordions */
.card-group .card-heading .accordion-toggle {
  display: block;
  text-decoration: none;
  font-size: 14px;
}

/* Tree */
.tree {
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 9px;
  position: relative;
}

.tree:before {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #67B2DD;
  border-image: none;
  border-style: dotted;
  border-width: 0 0 0 1px;
  bottom: 16px;
  content: "";
  display: inline-block;
  left: 0;
  position: absolute;
  top: -20px;
  z-index: 1;
}

.tree .tree-folder {
  cursor: pointer;
  min-height: 20px;
  width: auto;
}

.tree .tree-folder .tree-folder-header {
  height: 100%;
  line-height: 20px;
  position: relative;
}

.tree .tree-folder .tree-folder-header:hover {
  background-color: #f3f3f3;
}

.tree .tree-folder .tree-folder-header .tree-folder-name, .tree .tree-item .tree-item-name {
  display: inline;
  z-index: 2;
}

.tree .tree-folder .tree-folder-header > [class*=fa-]:first-child, .tree .tree-item > [class*=fa-]:first-child {
  display: inline-block;
  position: relative;
  top: -1px;
  z-index: 2;
}

.tree .tree-folder .tree-folder-header .tree-folder-name {
  margin-left: 2px;
}

.tree .tree-folder .tree-folder-header > [class*=fa-]:first-child {
  margin: -2px 0 0 -2px;
}

.tree .tree-folder:last-child:after {
  border-left: 1px solid #FFFFFF;
  bottom: 0;
  content: "";
  display: inline-block;
  left: -15px;
  position: absolute;
  top: 15px;
  z-index: 1;
}

.tree .tree-folder .tree-folder-content {
  margin-left: 23px;
  position: relative;
}

.tree .tree-folder .tree-folder-content:before {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #67B2DD;
  border-image: none;
  border-style: dotted;
  border-width: 0 0 0 1px;
  bottom: 16px;
  content: "";
  display: inline-block;
  left: -14px;
  position: absolute;
  top: -14px;
  z-index: 1;
}

.tree .tree-item {
  cursor: pointer;
  height: 100%;
  line-height: 20px;
  position: relative;
}

.tree .tree-item:hover {
  background-color: #f3f3f3;
}

.tree .tree-item .tree-item-name {
  margin-left: 3px;
}

.tree .tree-item .tree-item-name > [class*=fa-]:first-child {
  margin-right: 3px;
}

.tree .tree-item > [class*=fa-]:first-child {
  margin-top: -1px;
}

.tree .tree-folder, .tree .tree-item {
  position: relative;
}

.tree .tree-folder:before, .tree .tree-item:before {
  border-top: 1px dotted #67B2DD;
  content: "";
  display: inline-block;
  height: 0;
  left: -13px;
  position: absolute;
  top: 14px;
  width: 18px;
  z-index: 1;
}

.tree .tree-selected {
  background-color: rgba(98, 168, 209, 0.1);
  color: #6398B0;
}

.tree .tree-selected:hover {
  background-color: rgba(98, 168, 209, 0.1);
}

.tree .tree-item, .tree .tree-folder {
  border: 1px solid #FFFFFF;
}

.tree .tree-folder .tree-folder-header {
  border-radius: 0 0 0 0;
}

.tree .tree-item, .tree .tree-folder .tree-folder-header {
  -moz-box-sizing: content-box;
  margin: 0;
  padding: 5px;
}

.tree .tree-item > [class*=fa-]:first-child {
  -moz-box-sizing: content-box;
  background-color: #FAFAFA;
  border: 1px solid #CCCCCC;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #E6E6E6;
  font-size: 11px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  width: 13px;
}

.tree .tree-selected > [class*=fa-]:first-child {
  background-color: #88C425;
  border-color: #88C425;
  color: #FFFFFF;
}

.tree .icon-plus[class*=fa-]:first-child, .tree .icon-minus[class*=fa-]:first-child {
  -moz-box-sizing: content-box;
  background-color: #FFFFFF;
  border: 1px solid #8BAEBF;
  height: 11px;
  line-height: 10px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 11px;
  z-index: 1;
}

.tree .icon-plus[class*=fa-]:first-child:before {
  content: "+";
  display: block;
  font-family: "Open Sans";
  font-size: 16px;
  position: relative;
  z-index: 1;
}

.tree .icon-minus[class*=fa-]:first-child:before {
  border-top: 1px solid #4D6878;
  content: "";
  display: block;
  height: 0;
  left: 2px;
  position: absolute;
  top: 5px;
  width: 7px;
}

.tree .tree-unselectable .tree-item > [class*=fa-]:first-child {
  background-color: rgba(0, 0, 0, 0);
  border: 0 none;
  border-radius: 0 0 0 0;
  box-shadow: none;
  color: #5084A0;
  font-size: 10px;
  height: 13px;
  line-height: 13px;
  text-align: center;
  width: 13px;
}

.tree [class*=fa-][class*=-down] {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.tree .fa-adjust-hue {
  height: auto;
}

.tree .tree-loading {
  margin-left: 36px;
}

.tree img {
  display: inline;
}

/* Nestable lists */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  cursor: move;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: 600;
}

.dd-item > button:before {
  content: "+";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action=collapse]:before {
  content: "-";
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

#nestable-menu {
  padding: 0;
  margin: 20px 0;
}

#nestable-output,
#nestable2-output {
  width: 100%;
  height: 7em;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: Consolas, monospace;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
  color: #fff;
  border: 1px solid #999;
  background: #bbb;
  background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
  background: -moz-linear-gradient(top, #bbb 0%, #999 100%);
  background: linear-gradient(top, #bbb 0%, #999 100%);
}

#nestable2 .dd-handle:hover {
  background: #bbb;
}

#nestable2 .dd-item > button:before {
  color: #fff;
}

@media only screen and (min-width: 700px) {
  .dd {
    float: left;
    width: 48%;
  }

  .dd + .dd {
    margin-left: 2%;
  }
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #333;
  text-decoration: none;
  font-weight: 600;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 30px;
}

.dd3-handle {
  position: absolute;
  margin: 0;
  cursor: move;
  left: 0;
  top: 0;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: linear-gradient(top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dd3-handle:before {
  content: "≡";
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}

.dd3-handle:hover {
  background: #ddd;
}

/* Tables inside Modals */
.modal-table .modal-body {
  padding: 0;
}
.modal-table .modal-footer {
  border-top: none;
  margin-top: 0;
}

/* Forms */
.form-title {
  margin-top: 0px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
}

.field-icon {
  display: block;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 25px;
  top: 9px;
}

.textarea-transition {
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
}

.radio > div.radio, .radio-inline > div.radio, .checkbox > div.checker, .checkbox-inline > div.checker {
  margin-left: -20px;
  padding-top: 0;
}

/* BT WYSIWYG */
#editor {
  margin-top: 10px;
  max-height: 250px;
  height: 250px;
  background-color: white;
  border-collapse: separate;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: content-box;
  -webkit-box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  overflow: scroll;
  outline: none;
}

#voiceBtn {
  width: 20px;
  color: transparent;
  background-color: transparent;
  transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  -moz-transform: scale(2, 2);
  border: transparent;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
}

div[data-role=editor-toolbar] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-menu a {
  cursor: pointer;
}

/* Charts */
.chart, .pie, .bars {
  height: 300px;
  overflow: hidden;
}

.demo-container {
  height: 300px;
  border: 1px solid #ddd;
}

.demo-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
}

.legend table {
  border-spacing: 5px;
}

/* Xcharts */
.xchart .errorLine path {
  stroke: #C6080D;
  stroke-width: 3px;
}

.ex-tooltip {
  background: none repeat scroll 0 0 #EEEEEE;
  border-collapse: separate;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 3px #000000;
  display: none;
  padding: 5px;
  position: absolute;
}

/* Easy pie chart */
.piechart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  text-align: center;
}
.piechart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.piechart .percent {
  font-size: 1.2em;
  font-weight: 600;
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}

/* Sparkline */
.sparkline-row {
  margin-bottom: 30px;
  position: relative;
}
.sparkline-row .big {
  height: 30px;
  width: 83px;
}
.sparkline-row .title {
  display: block;
  font-size: 12px;
}
.sparkline-row .value {
  font-size: 17px;
  font-weight: 600;
}
.sparkline-row .sparkline, .sparkline-row .sparklinepie, .sparkline-row .linechart {
  position: absolute;
  right: 0;
  top: 0;
}
.sparkline-row .sparklinepie {
  position: absolute;
  right: 0;
  top: 0;
}

.sparkline-stats {
  position: relative;
}
.sparkline-stats ul {
  list-style: none outside none;
  margin: 0 0 0 -40px;
}
.sparkline-stats ul li {
  font-size: 12px;
  line-height: 32px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.sparkline-stats ul li div {
  float: left;
}
.sparkline-stats ul li div:first-child {
  margin-right: 5px;
}
.sparkline-stats ul li .number {
  color: #FA603D;
  font-size: 17px;
  font-weight: 700;
  padding: 0 0 0 2px;
}

/* Google Maps */
.gmaps {
  height: 300px;
  width: 100%;
}

/* Full calendar */
#external-events {
  padding: 0 10px;
  border: 1px solid #ccc;
  background: #eee;
  text-align: left;
  border-radius: 4px;
  margin-bottom: 20px;
}

#external-events h4 {
  font-size: 16px;
  margin-top: 0;
  padding-top: 1em;
}

.external-event {
  /* try to mimick the look of a real event */
  margin: 10px 0;
  padding: 2px 4px;
  background: #2d6cb4;
  color: #fff;
  cursor: move;
  font-size: 0.83em;
  border-radius: 4px;
}

#external-events p {
  margin: 1.5em 0;
  font-size: 11px;
  color: #666;
}

#external-events p input {
  margin: 0;
  vertical-align: middle;
}

/* Vector Maps */
.vmaps {
  height: 300px;
  overflow: hidden;
  position: relative;
}

/* Isotope filtering */
.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

.filter-content {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}
.filter-content .hover-content {
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
  display: none;
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 0s;
  -moz-animation-duration: 1s;
  -moz-animation-delay: 0s;
  -o-animation-duration: 1s;
  -o-animation-delay: 0s;
}
.filter-content .hover-content h4 {
  margin-top: 20%;
}
.filter-content .hover-content a.hover-link {
  margin: 0 5px;
  vertical-align: middle;
}
.filter-content .hover-content a.hover-link i {
  color: #FFFFFF;
}

/* Login Screen */
.login-v2 {
  background-color: #f6f7fa !important;
  height: 100%;
  min-height: 100%;
}
.login-v2 #logo {
  margin-top: 30px;
  text-align: center;
}
.login-v2 #page {
  background: none repeat scroll 0 0 #f6f7fa;
}

.login-box {
  width: 100%;
  max-width: 540px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(48, 89, 158, 0.1);
  background-color: #fff;
  color: #1e1e1e;
  margin: 150px auto;
  padding: 30px;
  position: relative;
  min-height: 500px;
  font-family: "Lato" !important;
}
.login-box label {
  font-family: "Lato" !important;
  font-size: 14px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.6 !important;
  letter-spacing: normal !important;
  color: #1e1e1e !important;
}
.login-box h1 {
  font-family: "Lato" !important;
  font-size: 34px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.62 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #1e1e1e !important;
  text-align: left !important;
}
.login-box .login-helpers {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0 !important;
  font-family: "Lato" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.62 !important;
  letter-spacing: normal !important;
}
.login-box .login-helpers a:hover {
  background-color: transparent !important;
  text-decoration: underline !important;
}
.login-box label.checkbox {
  color: #7e7e7e !important;
}
.login-box .login-logo {
  text-align: center !important;
}
.login-box input, .login-box textarea {
  background: none repeat scroll 0 0 #FFFFFF;
  font-size: 15px;
  margin: 0;
  padding: 8px 8px !important;
  min-height: 50px;
  width: 100%;
  border-radius: 5px;
  border: solid 1px #dddddd;
  background-color: #fff;
  box-shadow: none;
  min-width: 0;
}
.login-box button {
  width: auto;
  border-radius: 5px;
  background-color: #30599e;
  padding: 13px 30px;
  border-color: #30599e;
  float: right;
}
.login-box label.checkbox input {
  float: left;
  min-height: 20px;
  margin-right: 10px;
}

.login-box input#remember_me {
  width: auto;
}

#login_bg a, #register_bg a, #forgot_bg a {
  color: #30599e !important;
  text-decoration: none !important;
}

.login-box-plain {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 7px 7px 7px 7px;
  box-shadow: 0 1px 4px #D3D3D3;
  margin: 50px auto;
  padding: 55px 60px 60px;
  position: relative;
  min-height: 300px;
}
.login-box-plain input, .login-box-plain textarea {
  background: none repeat scroll 0 0 #FFFFFF;
  font-size: 15px;
  margin: 0;
  padding: 10px 8px 8px;
  width: 100%;
  border-radius: 6px;
  box-shadow: none;
  min-width: 0;
  padding-left: 33px !important;
}
.login-box-plain button {
  width: 100%;
}

.login-box form i, .login-box-plain form i {
  color: #CCCCCC;
  display: block !important;
  font-size: 16px;
  height: 16px;
  margin: 9px 2px 4px 10px;
  position: absolute !important;
  text-align: center;
  width: 16px;
  z-index: 1;
}

.login-box .social-login a, .login-box-plain .social-login a {
  border: 0 none;
  border-radius: 100% 100% 100% 100%;
  height: 42px;
  line-height: 42px;
  margin: 0 5px;
  padding: 0;
  width: 42px;
  text-decoration: none !important;
}

.login-helpers {
  margin-top: 20px;
  font-size: 12px;
}

.form-control {
  font-size: 0.95rem;
}

.btn-info, .btn-success {
  color: #ffffff !important;
}

.btn-info:hover, .btn-success:hover {
  color: #333333 !important;
}

.input-group-text {
  font-size: 0.95rem !important;
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.thumbnail h4.text-muted {
  font-size: 1rem !important;
}

.select2-container .select2-selection--single, .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 37px !important;
  font-size: 0.95rem !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px !important;
}

.form-control:focus {
  border-color: #CCCCCC;
  box-shadow: none !important;
}

.bigintro {
  font-size: 38px;
  line-height: 50px;
  text-align: center;
}

.logo a {
  transition: all 0.4s ease-in-out 0s;
}
.logo a:hover {
  opacity: 0.7;
}
.logo img {
  max-height: 50px;
}

.visible {
  display: block !important;
}

#login, #register, #forgot {
  display: none;
}

#login_bg, #register_bg, #forgot_bg {
  display: none;
}
#login_bg a, #register_bg a, #forgot_bg a {
  color: #FFFFFF;
  text-decoration: underline;
}
#login_bg a:hover, #register_bg a:hover, #forgot_bg a:hover {
  text-decoration: none;
}

/* Chat */
.chat-form {
  background-color: #2860a0;
  clear: both;
  overflow: hidden;
  padding: 10px;
  border-radius: 4px;
}

.chat-pop {
  background-color: #2d6cb4;
  padding: 8px;
  border-radius: 4px;
  position: relative;
  width: 70%;
}
.chat-pop .media-heading {
  font-weight: 400;
}
.chat-pop.mod {
  background-color: #23548b;
  text-align: right;
}
.chat-pop h4 abbr {
  font-size: 11px;
  text-transform: uppercase;
}
.chat-pop h4 i {
  font-size: 13px;
}

.chat-list img {
  border-radius: 99px;
}

/* Chat widget */
.chat-widget {
  bottom: 0;
  position: fixed;
  right: 20px;
  width: 400px;
  z-index: 999;
}
.chat-widget .box {
  margin-bottom: 0px;
}

.card {
  margin-bottom: 15px;
}

.card-title {
  font-size: 20px;
  color: inherit;
  margin-bottom: 0;
}

/* Address Book */
#contact-card .card-title {
  font-size: 16px;
  color: inherit;
}
#contact-card .card-body .headshot img {
  border-radius: 4px;
}

/* Pricing */
/*Pricing table and price blocks*/
.pricing_table {
  line-height: 150%;
  margin: 0 auto;
  width: 100%;
  padding-top: 10px;
}
.pricing_table .footer {
  padding: 15px;
  background: #23548b;
  border-radius: 0 0 4px 4px;
}

.price_block {
  text-align: center;
  color: #fff;
  float: left;
  list-style-type: none;
  transition: all 0.25s;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  border-bottom: 1px solid transparent;
  padding-left: 0px;
  /* padding-right: 0px; */
}

.price_block_static {
  text-align: center;
  color: #fff;
  float: left;
  list-style-type: none;
  transition: all 0.25s;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

/*Price heads*/
.pricing_table h3 {
  text-transform: uppercase;
  padding: 5px 0;
  background: #555555;
  margin: 0;
  border-radius: 4px 4px 0 0;
}

/*Price tags*/
.price {
  display: table;
  background: #898989;
  width: 100%;
  height: 70px;
}

.price_figure {
  font-size: 24px;
  text-transform: uppercase;
  vertical-align: middle;
  display: table-cell;
}

.price_number {
  font-weight: 600;
  display: block;
}

.price_tenure {
  font-size: 11px;
}

/*Features*/
.features {
  background: #E6E6E6;
  color: #555555;
  padding: 0;
}

.features li {
  padding: 8px 15px;
  border-bottom: 1px solid #ccc;
  font-size: 11px;
  list-style-type: none;
}

.price_block:hover {
  /* box-shadow: 0 0 0px 5px rgba(0, 0, 0, 0.5); */
  transform: scale(1.04) translateY(-5px);
  -webkit-transform: scale(1.04) translateY(-5px);
  z-index: 1;
  border-bottom: 0 none;
}

.price_block_static.active {
  -webkit-transform: scale(1.09) translateY(-5px);
  transform: scale(1.09) translateY(-5px);
  z-index: 1;
  border-bottom: 0 none;
  box-shadow: 0 0 0px 3px rgba(0, 0, 0, 0.3);
}

.price_block:hover .price.orange {
  background: #f0ad4e;
}

.price_block:hover .price.green {
  background: #88C425;
}

.price_block_static.active .price {
  background: #ee7599;
}

.price_block:hover h3 {
  background: #3c3c3c;
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  /* .price_block:nth-child(odd) {border-right: 5px solid transparent;} */
  .price_block:nth-child(3) {
    clear: both;
  }

  .price_block:nth-child(odd):hover {
    border: 0 none;
  }
}
@media only screen and (min-width: 768px) {
  /* .price_block {border-right: 5px solid transparent; border-bottom: 0 none;} */
  .price_block:last-child {
    border-right: 0 none;
  }

  .price_block:hover {
    border: 0 none;
  }
}
.skeleton, .skeleton ul, .skeleton li, .skeleton div, .skeleton h3, .skeleton span, .skeleton p {
  border: 5px solid rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  margin: 7px !important;
  background: rgba(0, 0, 0, 0.05) !important;
  padding: 0 !important;
  text-align: left !important;
  display: block !important;
  width: auto !important;
  height: auto !important;
  font-size: 10px !important;
  font-style: italic !important;
  text-transform: none !important;
  font-weight: normal !important;
  color: black !important;
}

.skeleton .label {
  font-size: 11px !important;
  font-style: italic !important;
  text-transform: none !important;
  font-weight: normal !important;
  color: white !important;
  border: 0 none !important;
  padding: 5px !important;
  margin: 0 !important;
  float: none !important;
  text-align: left !important;
  text-shadow: 0 0 1px white;
  background: none !important;
}

.skeleton {
  display: none !important;
  margin: 100px !important;
  clear: both;
}

/* Invoice */
@media print {
  .invoice-header .btn-group {
    display: none;
  }

  .payment-info {
    display: table;
    float: none;
    padding: 5px;
    margin: 0px;
  }

  .seller {
    display: table;
    float: left;
    padding: 5px;
    margin: 0px;
  }

  .buyer {
    display: table;
    float: right;
    padding: 5px;
    margin: 0px;
  }

  .seller i,
.buyer i,
.payment-info i {
    display: none;
  }

  .seller address,
.buyer address,
.payment-info address {
    float: left;
    font-size: 14px;
    margin: 0px;
  }

  .invoice-btn-group {
    display: none !important;
  }

  .amount {
    margin: 0px !important;
    font-weight: 400;
    font-size: 20px;
    float: right;
  }

  .invoice-title {
    font-size: 15px !important;
  }

  .divide-100 {
    display: none;
  }

  hr {
    margin: 0px;
  }
}
.invoice-header {
  display: block;
  margin-bottom: 20px;
}
.invoice-header h3 {
  font-weight: 400;
  margin-top: 0;
}

.seller, .buyer, .payment-info {
  padding: 20px;
}
.seller i, .buyer i, .payment-info i {
  color: #999999;
  float: left;
  font-size: 20px;
}
.seller address, .buyer address, .payment-info address {
  float: left;
  font-size: 15px;
  margin-left: 20px;
}

.invoice-title {
  color: #2d6cb4;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.amount {
  margin-right: 30px;
  color: #ee7599;
  font-weight: 600;
}

/* Orders */
.orders {
  padding: 0;
  opacity: 1;
  transition: opacity 0.2s linear 0s;
}
.orders .progress {
  margin-top: 10px;
}
.orders.no-opaque {
  opacity: 1;
}
.orders:hover {
  opacity: 1;
}
.orders ul {
  margin: 0;
}
.orders li {
  border-bottom: 1px solid #FFFFFF !important;
  border-top: 1px solid #E7E7E7 !important;
  background-color: #2d6cb4;
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
}
.orders li:hover {
  background-color: #5893d5;
}
.orders li:first-child {
  border-bottom: 1px solid #FFFFFF !important;
  border-top: none !important;
  border-radius: 4px 4px 0 0;
}
.orders li:last-child {
  border-radius: 0 0 4px 4px;
}
.orders li .cost {
  font-weight: 600;
}
.orders li abbr, .orders li .fa-clock-o {
  color: #B3B3B3;
}

/* Search */
.search-results {
  margin: 20px 0;
}
.search-results h4 {
  margin-bottom: 2px;
}
.search-results h4 a {
  color: #2d6cb4;
  text-decoration: underline;
}
.search-results .url {
  margin-top: 0px;
  color: #79af21;
  margin-bottom: 5px;
}
.search-results img {
  margin-right: 10px;
  border-radius: 2px;
}

/* 404 & 500 Errors*/
.not-found .error {
  color: #88C425;
  font-size: 128px;
  font-weight: 300;
  letter-spacing: -10px;
  line-height: 128px;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
  text-align: right;
  top: 35px;
  right: 50%;
}
.not-found .error-500 {
  color: #ee7599;
  font-size: 128px;
  font-weight: 300;
  letter-spacing: -10px;
  line-height: 128px;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
  text-align: right;
  top: 35px;
  right: 50%;
}
.not-found .content {
  display: inline-block;
  margin-left: 40px;
  padding-top: 0;
  text-align: left;
}
.not-found .content h3 {
  text-transform: uppercase;
}

#not-found-bg {
  background: url(../images/gallery/img/1.jpg) 0 0 no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
#not-found-bg .container {
  max-width: 1000px !important;
}
#not-found-bg .overlay {
  background: rgba(39, 62, 84, 0.82);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
}
#not-found-bg .error {
  color: #FFFFFF;
  font-size: 128px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 128px;
  margin-bottom: 20px;
  margin-top: 0;
  position: relative;
  text-align: left;
  right: 0;
  margin-left: 0px;
}
#not-found-bg .content {
  color: #FFFFFF;
  display: inline-block;
  margin-left: 0px;
  padding-top: 0;
  text-align: left;
}
#not-found-bg .content h3 {
  text-transform: uppercase;
}

/* FAQ */
#list-toggle .list-group i {
  margin-right: 10px;
  font-size: 15px;
}

/* USER PROFILE */
.user-profile.tabbable-custom > .tab-content {
  overflow: inherit;
}

.list-group-item.zero-padding {
  padding: 0;
}

.profile-details li i {
  color: #898989;
  cursor: pointer;
}

.profile-details li:hover i {
  color: #ee7599;
}

.user-profile .piechart {
  padding-bottom: 2px;
}
.user-profile .skill-name {
  text-transform: uppercase;
}

.feed-activity {
  border-bottom: 1px dotted #D0D8E0;
  border-left: 1px dotted #FFFFFF;
  border-right: 1px dotted #FFFFFF;
  padding: 10px 4px;
  position: relative;
}
.feed-activity:hover {
  background-color: #F4F9FD;
}
.feed-activity .roundicon {
  border-radius: 100% 100% 100% 100%;
  color: #FFFFFF;
  display: inline-block;
  font-size: 18px;
  height: 38px;
  line-height: 38px;
  margin-left: 0;
  margin-right: 15px !important;
  text-align: center;
  text-shadow: none !important;
  width: 38px;
  padding: 0px;
}
.feed-activity .time {
  bottom: 8px;
  position: absolute;
  right: 12px;
}

.form-actions {
  background-color: #F5F5F5;
  border-top: 1px solid #E5E5E5;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 19px 20px 20px;
}

#pro_edit .subtitle {
  display: block;
  font-weight: 600;
  padding-bottom: 5px;
}

#pro_edit .title {
  margin-top: 18px;
}

.form-group .col-form-label {
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 0;
  text-align: right;
}

/* DASHBOARD */
.dashbox .card-body:before, .dashbox .card-body:after {
  clear: both;
  content: "";
  display: table;
  line-height: 0;
}

.dashbox .card-body {
  padding: 0px;
}
.dashbox .card-left {
  width: 30%;
  float: left;
  padding: 20px;
  border-radius: 4px 0 0 4px;
  color: #FFFFFF;
  text-align: center;
  background-color: #2d6cb4;
  border-right: 1px solid #DDDDDD;
}
.dashbox .card-left.red i {
  color: #D9534F;
}
.dashbox .card-left.blue i {
  color: #70AFC4;
}
.dashbox .card-right {
  width: 70%;
  float: right;
  padding-left: 20px;
  padding-top: 10px;
  border-radius: 0 4px 4px 0;
  position: relative;
}
.dashbox .card-right .number {
  font-size: 30px;
  font-weight: 400;
  color: #666666;
}
.dashbox .card-right .title {
  text-transform: uppercase;
}
.dashbox .card-right .label {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 11px;
}

ul.stats {
  -moz-box-sizing: border-box;
  display: table;
  margin: 0;
  padding: 0;
  table-layout: fixed;
  width: 100%;
}

ul.stats:not(.no-dividers) li {
  border-right: 1px solid #D9D9D9;
}

ul.stats li {
  display: table-cell;
  padding: 15px;
}

ul.stats li:last-child {
  border-right: 0 none;
}

ul.stats li strong, ul.stats li small {
  display: block;
  padding: 5px 0;
  text-align: center;
}

ul.stats li strong {
  font-size: 25px;
  font-weight: bold;
}

ul.stats li small {
  color: #ADADAD;
  font-size: 11px;
}

/* Quick Pie charts Dashboards */
.quick-pie .title {
  color: #555555;
  display: block;
  font-size: 11px;
  font-weight: 400;
  margin-top: 5px;
  text-transform: uppercase;
}
.quick-pie .title:hover {
  text-decoration: none;
}

/* FOOTER GOTO TOP */
.footer-tools {
  display: inline-block;
  float: right;
}
.footer-tools .go-top {
  background-color: #193b62;
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: -2px;
  padding: 0 6px 2px;
  text-decoration: none;
  border-radius: 4px;
  color: #FFFFFF;
  text-transform: uppercase;
}
.footer-tools .go-top i {
  color: #FFFFFF;
}
.footer-tools:hover {
  opacity: 0.7;
}

/* GALLERY */
#filter-controls {
  margin-bottom: 20px;
}

.width-100 {
  width: 100%;
}

/* EMAIL LAYOUTS */
#email-layouts li {
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

/* FONT AWESOME */
#font-awesome a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
  color: #555555;
}

/* GRITTER */
#gritter-notice-wrapper {
  right: 1px !important;
}

.gritter-close {
  left: auto !important;
  right: 3px !important;
}

.gritter-title {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 300;
}

/* WELL BOTTOM */
.well.well-bottom {
  border-radius: 0 0 4px 4px;
}

.responsive-table-container {
  overflow-x: auto;
  padding: 0px;
}

.responsive-table-container div.dataTables_wrapper {
  width: 99.5%;
}

@media (max-width: 767.98px) {
  .login-box {
    margin: 10px auto;
  }
}
/* Colors */
/* Primary colors */
.titre-change-color {
  color: #fff;
}

.slimScrollBar {
  background: #fff !important;
}

#page {
  background: none repeat scroll 0 0 #F4F4F4;
}

.login #page {
  background: transparent;
}

.oeil {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-left-color: #cccccc;
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  display: table-cell;
  width: 63px;
  white-space: nowrap;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
}

/* Start Navbar */
.navbar {
  background: none repeat scroll 0 0 #5E87B0;
  border: none;
  border-radius: 0;
  margin: 0;
}
.navbar .navbar-brand {
  float: left;
  font-size: 18px;
  line-height: 20px;
  padding: 15px;
  width: 250px;
}
.navbar .navbar-brand img {
  position: absolute;
  max-width: 60%;
  height: 30px;
  width: 120px;
  top: 8px;
  left: 60px;
}
.navbar .dropdown-toggle {
  color: #FFFFFF;
}
.navbar .dropdown-toggle i {
  color: #FFFFFF;
  font-size: 17px;
}
.navbar .dropdown-toggle .badge {
  background-color: #DD5A43;
  border-radius: 99px !important;
  font-size: 12px !important;
  font-weight: 300;
  height: 16px;
  padding: 2px 6px;
  position: absolute;
  right: 24px;
  text-align: center;
  text-shadow: none !important;
  top: 8px;
}
.navbar .dropdown-toggle .username {
  font-weight: 600;
}
.navbar #header-notification .dropdown-toggle i, .navbar #header-message .dropdown-toggle i, .navbar #header-tasks .dropdown-toggle i {
  color: #486e93;
  text-shadow: 1px 1px #b4c7da, -1px -1px #375471;
}
.navbar #navbar-left li.dropdown {
  background-color: #6f94b8;
  border-right: 1px solid #507aa4;
  font-weight: 400;
}
.navbar #navbar-left li.dropdown:first-child {
  border-left: 1px solid #507aa4;
}
.navbar #navbar-left i {
  margin-right: 5px;
  color: #FFFFFF;
}
.navbar .nav.navbar-nav.float-right {
  min-width: 300px;
}

.navbar .nav > li > a:hover, .navbar .nav > li > a:focus, .navbar .nav .open > a, .navbar .nav .open > a:hover, .navbar .nav .open > a :focus {
  background-color: #80a1c1 !important;
}

/* Navbar Dropdown */
.dropdown-menu {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #C7CBD5;
  display: none;
  float: left;
  left: 0;
  font-size: 14px;
  list-style: none outside none;
  margin: 0;
  max-width: 300px;
  min-width: 166px;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.dropdown-menu .dropdown-title {
  background: none repeat scroll 0 0 #486e93;
  color: #FFFFFF;
  display: block;
  font-weight: 600;
  line-height: 30px;
  padding: 5px 10px;
}
.dropdown-menu .dropdown-title i {
  margin-right: 5px;
}
.dropdown-menu .footer {
  display: block;
}
.dropdown-menu .footer a {
  background-color: #dbdbdb !important;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-bottom: none !important;
  border-top: none !important;
}
.dropdown-menu .footer a i {
  margin-left: 5px;
  font-size: 16px;
}
.dropdown-menu ul {
  margin: 0 !important;
  padding: 0 !important;
}

/* Navbar Settings Menu */
.dropdown-menu.skins {
  border-top: 1px solid #486e93;
}

/* Navbar Right Menu */
.dropdown-menu {
  background-color: #F4F4F4;
}
.dropdown-menu li a {
  background-color: #F4F4F4;
  border-bottom: 1px solid #FFFFFF;
  border-top: 1px solid #E7E7E7;
  display: block;
  line-height: 40px;
  padding: 0 10px;
}
.dropdown-menu li a:hover {
  color: #333333;
  background-color: white;
}
.dropdown-menu.context {
  /* For smaller menus */
}
.dropdown-menu.context li a {
  line-height: 30px;
}
.dropdown-menu .divider {
  height: 1px;
  border-bottom: 0 !important;
  border-top: 1px solid #cecece !important;
  color: #555555 !important;
  margin: 0 !important;
}

/* Notification */
.dropdown-menu.notification {
  border-top: 1px solid #486e93;
  width: 270px;
}
.dropdown-menu.notification .label {
  color: #FFFFFF;
  display: inline-block;
  margin: -1px 10px -5px -10px !important;
  padding: 9px;
  text-align: center;
  width: 40px;
  height: 42px;
  border-radius: 0;
  position: absolute;
}
.dropdown-menu.notification .label i {
  font-size: 18px;
}
.dropdown-menu.notification .body {
  position: relative;
  display: inline-block;
  line-height: 20px;
  vertical-align: middle;
  white-space: normal;
  margin: 5px 5px 5px 40px;
  width: 100%;
}
.dropdown-menu.notification .message {
  line-height: 16px;
  display: inline-block;
  margin: 0 5px 5px 0;
}
.dropdown-menu.notification .time {
  color: #777777;
  display: inline-block;
  font-size: 12px;
}

/* Messages */
.dropdown-menu.inbox {
  border-top: 1px solid #486e93;
  width: 280px;
}
.dropdown-menu.inbox img {
  border-radius: 50em 50em 50em 50em;
  height: 40px;
  margin-right: 10px;
  margin-top: 5px;
  width: 40px;
}
.dropdown-menu.inbox .body {
  position: relative;
  display: inline-block;
  line-height: 20px;
  max-width: 220px;
  vertical-align: middle;
  white-space: normal;
  margin: 5px 0;
}
.dropdown-menu.inbox .from {
  display: inline-block;
  line-height: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.dropdown-menu.inbox .message {
  line-height: 16px;
  display: block;
  margin-bottom: 5px;
}
.dropdown-menu.inbox .time {
  color: #777777;
  display: block;
  font-size: 12px;
}
.dropdown-menu.inbox .compose {
  display: block;
}
.dropdown-menu.inbox .compose :hover {
  cursor: pointer;
}

/* Tasks */
.dropdown-menu.tasks {
  border-top: 1px solid #486e93;
  width: 250px;
}
.dropdown-menu.tasks .progress {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  height: 9px !important;
}

/* User Menu */
.dropdown.user a.dropdown-toggle {
  padding: 10px 10px 11px;
}
.dropdown.user .dropdown-menu {
  border-top: none;
}
.dropdown.user img {
  margin-right: 5px;
  border-radius: 99px;
  height: 29px;
  width: 29px;
  /* filter: url("data:image/svg+xml;filter: gray;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); */
}
.dropdown.user a:hover img, .dropdown.user a:focus img {
  filter: none;
}

/* End Navbar */
/* Start Sidebar */
#sidebar {
  background: none repeat scroll 0 0 #F4F4F4;
  padding: 0 !important;
  width: 250px;
  position: absolute;
  border-bottom: 1px solid #E7E7E7;
}

.sidebar-fixed {
  position: fixed !important;
}

#sidebar * {
  text-overflow: ellipsis;
}

.sidebar-menu > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  margin-top: 15px;
  border-bottom: 1px solid #FFFFFF !important;
  border-top: 1px solid #FFFFFF !important;
}

.sidebar-menu > ul > li {
  display: block;
  margin: 0;
  padding: 0;
  border: 0px;
}

.sidebar-menu > ul > li > a {
  display: block;
  position: relative;
  margin: 0;
  border: 0 none;
  padding: 15px 15px 15px 20px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar-menu > ul > li a i {
  color: #555555;
  font-size: 16px;
  margin-right: 5px;
}

.sidebar-menu > ul > li.has-sub {
  position: relative;
}

.sidebar-menu > ul > li.has-sub .sub {
  position: relative;
  background: #e7e7e7;
  border-top: 1px solid #E7E7E7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}
.sidebar-menu > ul > li.has-sub .sub .has-sub-sub .sub-sub {
  position: relative;
  background: #e7e7e7;
  border-top: 1px solid #E7E7E7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}

.sidebar-menu > ul > li.active > a {
  border: none;
  text-shadow: none;
}

.sidebar-menu ul > li > a .arrow:before {
  float: right;
  margin-top: 1px;
  margin-right: 5px;
  display: inline;
  font-size: 16px;
  font-family: FontAwesome;
  height: auto;
  content: "\f0d9";
  font-weight: 300;
  text-shadow: none;
}

.sidebar-menu > ul > li > a .arrow.open:before {
  float: right;
  margin-top: 1px;
  margin-right: 5px;
  display: inline;
  font-family: FontAwesome;
  height: auto;
  font-size: 16px;
  content: "\f0d7";
  font-weight: 300;
  text-shadow: none;
}

.sidebar-menu > ul > li > ul.sub:before {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #3c3c3c;
  border-image: none;
  border-style: dotted;
  border-width: 0 0 0 1px;
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
}

.sidebar-menu > ul > li > ul.sub > li:before {
  border-top: 1px dotted #3c3c3c;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  margin: 5% 0 0;
}

.sidebar-menu > ul > li > ul.sub {
  display: none;
  list-style: none;
  clear: both;
  padding-left: 25px;
}
.sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub {
  display: none;
  list-style: none;
  clear: both;
}

.sidebar-menu > ul > li.active > ul.sub {
  display: block;
}
.sidebar-menu > ul > li.active > ul.sub .has-sub-sub ul.sub-sub {
  display: block;
}

.sidebar-menu > ul > li > ul.sub > li, .sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li {
  background: none;
  margin: 0px;
  padding: 0px;
  margin-top: 1px !important;
}

.sidebar-menu > ul > li > ul.sub > li > a {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  padding-left: 30px !important;
  color: #555555;
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  background: none;
}

.sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li > a {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 5px 0px;
  padding-left: 30px !important;
  color: #555555;
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  background: none;
}

.sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li :hover, .sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li :focus {
  text-decoration: underline;
}

.sidebar-menu > ul > li > ul.sub > li > a > i, .sidebar-menu > ul > li > ul.sub .has-sub-sub ul.sub-sub > li > a > i {
  font-size: 13px;
}

.sidebar-menu > ul > li > a {
  border-bottom: 1px solid #FFFFFF !important;
  border-top: 1px solid #E7E7E7 !important;
  color: #555555 !important;
}

.sidebar-menu > ul > li:last-child > a {
  border-bottom: 1px solid transparent !important;
}

.sidebar-menu > ul > li:first-child > a {
  /* border-top-color: transparent !important; */
}

.sidebar-menu > ul > li a i {
  color: #555555;
}

.sidebar-menu > ul > li.has-sub.open > a,
.sidebar-menu > ul > li > a:hover,
.sidebar-menu > ul > li:hover > a {
  background: white;
}

.sidebar-menu > ul > li.has-sub.open > ul > li.has-sub-sub.open > a {
  background: white;
}

.sidebar-menu > ul > li.active > a {
  background: #dbdbdb !important;
  border-top-color: transparent !important;
  color: #fff;
}

.sidebar-menu > ul > li > a.open {
  background: #313131;
}

.sidebar-menu ul > li > a .arrow:before,
.sidebar-menu > ul > li > a .arrow.open:before {
  color: #555555 !important;
}

/*.sidebar-menu ul > li.active > a .arrow:before,
.sidebar-menu > ul > li.active > a .arrow.open:before {
   color: #fff !important;
} */
.sidebar-menu > ul > li > ul.sub > li > a {
  color: #555555;
  margin-left: 0px;
  padding-left: 5px;
  font-size: 13px;
  font-weight: 400;
}

.sidebar-menu > ul > li > ul.sub > li:first-child > a {
  border-top: 0px !important;
}

.sidebar-menu > ul > li > ul.sub > li.active > a,
.sidebar-menu > ul > li > ul.sub > li > a:hover,
.sidebar-menu > ul > li > ul.sub > li.current {
  background: #f4f4f4 !important;
}

.sidebar-menu > ul > li > ul.sub > li > a:hover {
  background: #f4f4f4 !important;
}

/* Search */
.sidebar-menu #search-bar {
  display: block;
  text-align: center;
}
.sidebar-menu #search-bar .search {
  background: none repeat scroll 0 0 white;
  border: 0 none;
  border-radius: 4px;
  color: #555555;
  padding: 8px 10px;
  width: 90%;
  margin: 0 auto 10px;
  padding-right: 30px !important;
}
.sidebar-menu #search-bar .search-icon {
  display: block;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 22px;
  top: 29px;
  color: #888888;
}

/* Mini Menu Search */
.mini-menu .sidebar-menu #search-bar {
  display: block;
  text-align: center;
}
.mini-menu .sidebar-menu #search-bar .search {
  background: none repeat scroll 0 0 white;
  border: 0 none;
  border-radius: 4px;
  color: #555555;
  padding: 8px 10px;
  width: 90%;
  margin: 0 auto 10px;
}
.mini-menu .sidebar-menu #search-bar .search-icon {
  display: block;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 22px;
  top: 29px;
  color: #888888;
}

/* Quick launch */
.sidebar-menu .quicklaunch-lg {
  line-height: 39px;
  margin-bottom: 0;
  max-height: 41px;
  text-align: center;
}
.sidebar-menu .quicklaunch-lg .btn {
  margin-right: 1rem;
}

.sidebar-menu .quicklaunch-mini {
  display: none;
  font-size: 0;
  line-height: 18px;
  padding-bottom: 2px;
  padding-top: 2px;
  text-align: center;
  width: 50px;
}

/* Sidebar collapse */
.sidebar-collapse, .switcher {
  padding: 10px 10px 0 0;
  position: absolute;
  top: 10px;
  left: 10px;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #FFFFFF;
  padding: 6px 8px;
}

.sidebar-collapse:hover, .switcher:hover {
  background-color: #507aa4;
}

.sidebar-collapse > [class*=fa-], .switcher > [class*=fa-] {
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  padding: 0 5px;
  position: relative;
  float: right;
}

.menu-text .badge {
  position: absolute;
  right: 35px;
  line-height: 15px;
  padding-bottom: 3px;
  padding-top: 2px;
  text-shadow: none;
  background-color: #DD5A43 !important;
}

/* Min Menu */
.mini-menu#sidebar {
  width: 50px;
}

.mini-menu#sidebar:before {
  width: 50px;
}

.mini-menu#sidebar .sidebar-menu > ul > li > a > .menu-text .badge {
  top: 15px;
  right: 10px;
}
.mini-menu#sidebar .sidebar-menu > ul > li > a > .menu-text .label {
  position: absolute;
  top: 15px;
  right: 10px;
}

.mini-menu#sidebar .sidebar-menu > ul > li > a > .menu-text {
  background-color: #F4F4F4;
  display: none;
  height: 50px;
  left: 50px;
  line-height: 38px;
  padding-left: 12px;
  position: absolute;
  top: 0;
  width: 198px;
  padding: 5px 0 0 15px;
  z-index: 121;
  border: 1px solid #cecece;
}

.mini-menu#sidebar .sidebar-menu > ul > li.has-sub > a > .menu-text {
  border-right: none;
  border-top: none;
}

.mini-menu#sidebar .sidebar-menu > ul > li:hover > a > .menu-text {
  display: block;
}

.mini-menu#sidebar .sidebar-menu > ul > li > ul.sub {
  background-color: #e7e7e7;
  border: 1px solid #cecece;
  display: none !important;
  left: 50px;
  padding-bottom: 2px;
  padding-top: 50px;
  position: absolute;
  top: 0;
  width: 200px;
  z-index: 120;
  border-top: 1px solid #E7E7E7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
}

.mini-menu#sidebar .sidebar-menu > ul > li:hover > ul.sub {
  display: block !important;
}

.mini-menu#sidebar .sidebar-menu ul > li > a .arrow:before {
  display: none;
}

.mini-menu#sidebar .sidebar-menu > ul > li > a .arrow.open:before {
  display: none;
}

.mini-menu#sidebar .quicklaunch-mini {
  display: block;
}

.mini-menu#sidebar #quicklaunch {
  position: relative;
}

.mini-menu#sidebar .quicklaunch-lg {
  background-color: #F4F4F4;
  display: none;
  left: 50px;
  position: absolute;
  top: -1px;
  width: 190px;
  z-index: 20;
  padding: 0 0 0 1px;
  max-height: 50px;
  height: 115%;
  border: 1px solid #cecece;
}
.mini-menu#sidebar .quicklaunch-lg .btn {
  margin-right: 0.25rem;
}

.mini-menu#sidebar #quicklaunch:hover .quicklaunch-lg {
  display: block;
}

/* End Sidebar */
/* Team Status */
.team-status {
  background-color: #6f6f6f;
  display: none;
  border-bottom: 2px solid #555555;
}

.team-status.open {
  display: block;
}

.team-status .team-list {
  height: 100%;
  list-style: none outside none;
  margin: 0 auto;
  padding: 15px 10px 20px;
  text-align: center;
}

.team-status .team-list li {
  color: #FFFFFF;
  display: inline-block;
  padding: 0 30px;
}

.team-status .team-list li:last-child {
  border-right: 0 none;
}

.team-status .team-list li a {
  color: #FFFFFF;
  display: block;
  opacity: 0.9;
  padding: 15px;
  border-radius: 4px;
  min-width: 200px;
}
.team-status .team-list li a .progress {
  margin-top: 10px !important;
  height: 9px !important;
  margin-bottom: 10px !important;
}
.team-status .team-list li a .status {
  text-align: left;
  font-size: 11px;
  text-transform: uppercase;
}
.team-status .team-list li a .status .field {
  margin-bottom: 5px;
}

.team-status .team-list li a:hover {
  background-color: rgba(255, 255, 255, 0.07);
  opacity: 1;
  text-decoration: none;
}

.team-status .team-list li .image, .team-status .team-list li .title {
  display: block;
}

.team-status .team-list li .image img {
  height: 45px;
  width: 45px;
  border-radius: 150px 150px 150px 150px;
}

.team-status .team-list li .title {
  font-weight: 600;
}

.team-status .team-list li .image + .title {
  padding-top: 10px;
}

.team-status .team-list li.current a {
  background-color: rgba(255, 255, 255, 0.07);
  opacity: 1;
  text-decoration: none;
}

.selectDyn {
  width: 100%;
}

/* Colors
$navbar-primary:		#5E87B0;
$sidebar-primary:		#F4F4F4;
$sidebar-border-bottom:	#FFFFFF;
$sidebar-border-top:	#E7E7E7;
$sidebar-active:		darken($sidebar-primary, 10%);
$body-font-color: 		#555555;
$sidebar-font-color: 	#555555;
$sidebar-icons:			#555555;
$sidebar-hover:			lighten($sidebar-primary, 15%);
$sidebar-sub:			darken($sidebar-primary, 5%);
$item-border:			#CDD2D2;
$badge-red:				#DD5A43;
$color-light:			#F4F4F4;
$content-color:			#F5F6F6;
$color-green:			$brand-success;*/
/* Primary colors
$brand-primary:         #5E87B0;
$brand-success:         #A8BC7B;
$brand-warning:         #fbc815;
$brand-danger:          #D9534F;
$brand-info:            #70AFC4;
$primary-color:			$brand-primary;
$green-color:			$brand-success;
$blue-color:			$brand-info;
$orange-color:			$brand-warning;
$red-color:				$brand-danger;
$dark-color:			#555555;
$purple-color:			#A696CE;
$pink-color:			#DB5E8C;
$grey-color:			#898989;
$light-grey-color:		#E6E6E6;
$yellow-color:			#FCD76A;
$dark-orange-color:		#F38630;*/
@media only screen and (max-width: 767px) {
  body {
    overflow-x: hidden;
  }

  /* NAVBAR */
  .navbar .navbar-nav.float-right {
    display: block;
    float: none !important;
    background-color: #404040;
    text-align: center;
  }

  .navbar .nav > li > a:hover, .navbar .nav > li > a:focus, .navbar .nav .open > a, .navbar .nav .open > a:hover, .navbar .nav .open > a :focus {
    background-color: #5a5a5a !important;
  }

  .navbar .navbar-brand {
    float: none;
    height: 45px;
    width: 100%;
  }
  .navbar .navbar-brand img {
    left: 10px;
  }
  .navbar .navbar-brand a.switcher {
    position: absolute;
    left: auto;
    right: 70px;
  }
  .navbar .navbar-brand a.switcher i {
    font-size: 14px;
  }
  .navbar .nav li.dropdown {
    display: inline-block;
    vertical-align: middle;
  }
  .navbar .dropdown-menu {
    position: absolute !important;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    text-align: left;
  }
  .navbar #header-notification .dropdown-toggle i, .navbar #header-message .dropdown-toggle i, .navbar #header-tasks .dropdown-toggle i {
    color: #FFFFFF;
    text-shadow: none;
  }

  .navbar-nav {
    margin: 5px -15px 0px;
  }
  .navbar-nav .open .dropdown-menu.notification {
    min-width: 270px;
    right: auto !important;
    left: 0 !important;
  }
  .navbar-nav .open .dropdown-menu.inbox {
    min-width: 280px;
    left: -50px !important;
  }
  .navbar-nav .open .dropdown-menu.tasks {
    min-width: 250px;
    left: -100px !important;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 40px;
    padding: 0 10px;
  }
  .navbar-nav .open .dropdown-menu > li.footer > a {
    line-height: 30px;
    padding: 0 10px;
  }
  .navbar-nav > li > a {
    padding: 15px;
  }

  .dropdown.user a.dropdown-toggle {
    padding: 10.5px;
  }

  /* SIDEBAR */
  .sidebar {
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
    right: -250px;
    position: absolute;
    top: 0 !important;
    z-index: 1001;
    transition: right 0.3s ease 0s;
    display: none;
    -webkit-transform: scale3d(1, 1, 1);
  }
  .sidebar.mini-menu {
    box-shadow: none;
    right: auto;
    top: auto !important;
    z-index: 1;
  }

  body {
    right: 0;
    transition: right 0.3s ease 0s;
  }

  .slidebar {
    position: absolute;
    right: 250px;
  }
  .slidebar .sidebar {
    right: 0;
    width: 250px !important;
    display: block;
    height: 100%;
  }
  .slidebar #content {
    border-right: 1px solid #CDD2D2;
  }

  .sidebar-collapse {
    right: 20px;
    left: auto;
  }

  /* DATE RANGE */
  .date-range.float-right {
    display: block;
    float: none !important;
    padding-bottom: 5px;
  }

  /* CONTENT TITLE */
  .content-title.float-left {
    display: block;
    float: none !important;
  }

  /* CALENDAR */
  .fc-header-left, .fc-header-center, .fc-header-right {
    display: block;
    float: none !important;
  }

  /* TABS AND ACCORDIONS */
  .box .box-tabs .nav-tabs {
    top: 0;
  }
  .box .box-tabs .nav-tabs > li {
    display: block;
    float: none;
  }

  .box.border.orange .box-tabs .nav-tabs,
.box.border.blue .box-tabs .nav-tabs,
.box.border.red .box-tabs .nav-tabs,
.box.border.green .box-tabs .nav-tabs,
.box.border.primary .box-tabs .nav-tabs,
.box.border.pink .box-tabs .nav-tabs,
.box.border.purple .box-tabs .nav-tabs,
.box.border.inverse .box-tabs .nav-tabs {
    top: 0;
  }
  .box.border.orange .box-tabs .nav-tabs > li,
.box.border.blue .box-tabs .nav-tabs > li,
.box.border.red .box-tabs .nav-tabs > li,
.box.border.green .box-tabs .nav-tabs > li,
.box.border.primary .box-tabs .nav-tabs > li,
.box.border.pink .box-tabs .nav-tabs > li,
.box.border.purple .box-tabs .nav-tabs > li,
.box.border.inverse .box-tabs .nav-tabs > li {
    display: block;
    float: none;
  }
  .box.border.orange .box-tabs .nav-tabs > li > a,
.box.border.blue .box-tabs .nav-tabs > li > a,
.box.border.red .box-tabs .nav-tabs > li > a,
.box.border.green .box-tabs .nav-tabs > li > a,
.box.border.primary .box-tabs .nav-tabs > li > a,
.box.border.pink .box-tabs .nav-tabs > li > a,
.box.border.purple .box-tabs .nav-tabs > li > a,
.box.border.inverse .box-tabs .nav-tabs > li > a {
    color: #666666;
  }
  .box.border.orange .box-tabs .nav-tabs > li.active a,
.box.border.blue .box-tabs .nav-tabs > li.active a,
.box.border.red .box-tabs .nav-tabs > li.active a,
.box.border.green .box-tabs .nav-tabs > li.active a,
.box.border.primary .box-tabs .nav-tabs > li.active a,
.box.border.pink .box-tabs .nav-tabs > li.active a,
.box.border.purple .box-tabs .nav-tabs > li.active a,
.box.border.inverse .box-tabs .nav-tabs > li.active a {
    font-weight: 600;
    color: #555555;
  }
  .box.border.orange .box-tabs .nav-tabs > li.active a:before,
.box.border.blue .box-tabs .nav-tabs > li.active a:before,
.box.border.red .box-tabs .nav-tabs > li.active a:before,
.box.border.green .box-tabs .nav-tabs > li.active a:before,
.box.border.primary .box-tabs .nav-tabs > li.active a:before,
.box.border.pink .box-tabs .nav-tabs > li.active a:before,
.box.border.purple .box-tabs .nav-tabs > li.active a:before,
.box.border.inverse .box-tabs .nav-tabs > li.active a:before {
    color: #333;
    display: inline;
    font-size: 16px;
    font-family: FontAwesome;
    height: auto;
    content: "\f00c";
    font-weight: 300;
    text-shadow: none;
    padding: 0 5px;
  }

  /* HUBSPOT MESSENGER */
  ul.messenger.messenger-fixed.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-left {
    width: 50% !important;
  }

  /* WIZARDS AND VALIDATION */
  .form-wizard .nav-justified > li > a {
    text-align: left;
  }

  /* CHAT WIDGET */
  .chat-widget {
    right: 5px;
    width: 85%;
  }

  /* PRICING */
  .pricing_table {
    margin: 0 -15px 10px -15px;
  }

  /* INVOICE */
  .invoice-header {
    margin-bottom: 20px;
  }
  .invoice-header .float-left, .invoice-header .float-right {
    display: block;
    float: none !important;
  }

  .amount {
    margin-right: 0;
  }

  /* ERROR 404 & 500 */
  .not-found .error {
    right: 30%;
  }
  .not-found .error-500 {
    right: 30%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 800px) {
  body {
    overflow-x: hidden;
  }

  #navbar-left .name {
    display: none;
  }
}